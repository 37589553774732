import React, {Fragment} from "react";
import { Link } from "react-router-dom";

// Core React 
import CbInput from "./../admin/common/forms/cb-input";
import TextInput from "./../admin/common/forms/text-input";
import Logo from './../../assets/img/core/esuubi-white-logo.webp';

import Error from "../admin/common/forms/error";
import WordingContainer from "../../containers/common/wording/wording-container";

function GiftAid(props) {

    return (
        <div className="donate">
            <div className="half title red">
                <Link to="/"><img src={Logo} alt="Esuubi  Logo" /></Link>
                <h1>Donate</h1>
                <h4>Thanks for your donation!</h4>
            </div>

            <div className="half form">

                <div className="form">
                    <Link to="/" className="right back-lnk">Home</Link>
                    <h2>Gift Aid</h2>

                    <WordingContainer wordName="gift-aid-intro" default="Thanks for your donation. If you are a UK registered tax payer, we can claim 25% more in Gift Aid. Just provide your details below." />

                    <Error error={props.error} />

                    { props.complete ? (
                        <Fragment>
                            <Link to="/" className="btn">Return to the homepage</Link>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <TextInput label="First name" fieldName="firstName" handleChange={props.setUserDetail} value={ props.userDetails.firstName } valErrors={ props.valErrors } valName="FirstName" />
                                <TextInput label="Last name" fieldName="lastName" handleChange={props.setUserDetail} value={props.userDetails.lastName} valErrors={props.valErrors} valName="LastName" />

                                <TextInput label="Address line 1" fieldName="address1" handleChange={props.setUserDetail} value={props.userDetails.address1} valErrors={props.valErrors} valName="Address1" />
                                <TextInput label="Address line 2" fieldName="address2" handleChange={props.setUserDetail} value={props.userDetails.address2} valErrors={props.valErrors} valName="Address2" />
                                <TextInput label="Town / City" fieldName="address3" handleChange={props.setUserDetail} value={props.userDetails.address3} valErrors={props.valErrors} valName="Address3" />
                                <TextInput label="Postcode" fieldName="postcode" handleChange={props.setUserDetail} value={props.userDetails.postcode} valErrors={props.valErrors} valName="Postcode" />
                                <TextInput label="Country" fieldName="country" handleChange={props.setUserDetail} value={props.userDetails.country} valErrors={props.valErrors} valName="Country" />

                            <div className="input textarea">
                                <label htmlFor="message">Message</label>
                                <textarea name="message" id="message" onChange={(e) => props.setUserDetail("message", e.target.value)} value={props.userDetails.message}></textarea>
                            </div>

                            <CbInput label="I confirm that I am a UK taxpayer and am eligible to claim Gift Aid on this donation" fieldName="giftAid" updateField={props.setUserDetail} />
                            <CbInput label="I would like to receive the Esuubi email newsletter" fieldName="newsletter" updateField={props.setUserDetail} />

                            <button onClick={(e) => props.saveData(e)}>Save details</button>
                        </Fragment>
                    )}
                    
                </div>
            </div>
        </div>
    );
}

export default GiftAid;