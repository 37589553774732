// Components
import MenusContainer from "../../containers/pages/menus-container";

// Assets
import WhiteLogo from './../../assets/img/core/esuubi-white-logo.webp';
import Facebook from './../../assets/img/icons/facebook-white.png';
import Twitter from './../../assets/img/icons/twitter-white.png';

function Footer() {
    return (
        <footer className="final">
            <div className="content">
                <section className="width-12 centred">
                    <img src={WhiteLogo} alt="Esubi logo" className="logo" />

                    <nav className="footer">
                        <ul>
                            <MenusContainer menuSlug="footer" />
                        </ul>
                    </nav>

                    <div className="smallprint">
                        <p><a href="mailto:info@esuubi.org.uk">info@esuubi.org.uk</a></p>
                        <p>Esuubi, 119 Kenwood Drive, Beckenham, Kent, BR3 6RB</p>
                        <p className="sml">Esuubi is a UK registered charity (1115864) and company (5863107)</p>
                        <ul className="social">
                            <li>
                                <a href="https://www.facebook.com/esuubi.trust" target="_blank" rel="noreferrer"><img src={Facebook} alt="Find us on Facebook" /></a>
                            </li>
                            {/*<li>
                                <a href="#twitter"><img src={Twitter} alt="Find us on Twitter" /></a>
                            </li>*/}
                        </ul>
                    </div>
                </section>
            </div>
        </footer>
    );
}

export default Footer;
