import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import CryptoJS from 'crypto-js';
import Fetching from '../../../utilities/fetching';
import NewsArticles from '../../../components/blocks/public/sub-blocks/news-articles';
import moment from 'moment';

function NewsArticlesContainer(props) {

    // Set up the state to track status 
    const [articles, setArticles] = useState(false);
    const [loading, setLoading] = useState(true);

    const [authCookie] = useCookies([process.env.REACT_APP_COOKIE]);
    let encSecret = process.env.REACT_APP_ENC_KEY;

    // Get the auth state from the cookies - to pass down
    const loadArticles = () => {

        // Check for a cookie
        var isAdmin = false;
        if (authCookie && Object.keys(authCookie).length > 0 && typeof authCookie[process.env.REACT_APP_COOKIE] != "undefined" && typeof authCookie[process.env.REACT_APP_COOKIE] !== "undefined") {

            var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
            reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));
            isAdmin = true;

        }

        // Set up the auth headers 
        var reqHeaders = {
            "Content-type": "application/json"
        };
        if (isAdmin) {
            reqHeaders['api-token'] = reformData.apiToken;
            reqHeaders['api-key'] = reformData.apiKey;
        }

        // Run the save itself 
        var dateTime = moment().format('YYYY-MM-DD HH:mm:ss');
        dateTime = dateTime.replace(" ", "T").replace(/:/g, "C");
        fetch(process.env.REACT_APP_API_BASE + '/latest/' + props.limit + "/" + dateTime, {
            method: 'get',
            headers: reqHeaders
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if (typeof data.rslt != "undefined" && data.rslt === "success") {
                setLoading(false);
                setArticles(data.detail);
                return;
            }
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

    }

    // Trigger the load on page load
    /* eslint-disable */
    useEffect(() => {
        loadArticles();
    }, []);
    /* eslint-enable */

    return (
        <NewsArticles
            articles={articles}
            loading={loading}
        />
    );
}

export default NewsArticlesContainer;
