import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ExtractContent from '../../../utilities/extract-content';
import DisplayImageContainer from '../../../containers/images/display-image-container';

function Banner(props) {

    let content = ExtractContent.block(props.block);

    return (
        <section className="banner">
            {content['img'] > 0 &&
                <DisplayImageContainer
                    imageId={content["img"]}
                    size="half"
                    class="banner-inner"
                    isBgImage={true}
                />
            }
            <div className="content">
                <div className="width-10 centred">
                    <h4>&quot;{ content.quote }&quot;</h4>
                    {typeof content["action-txt-1"] != "undefined" && typeof content["action-link-1"] != "undefined" &&
                        <Fragment>
                            {content["action-link-1"].indexOf("http") > -1 ? (
                                <a className="btn sml" href={content["action-link-1"]} target='_blank' rel="noreferrer">{content["action-txt-1"]}</a>
                            ) : (
                                <Link className="btn sml" to={content["action-link-1"]}>{content["action-txt-1"]}</Link>
                            )}
                        </Fragment>
                    }
                </div>
            </div>
        </section>
    );
}

export default Banner;
