import React from 'react';
import { Routes,Route } from 'react-router-dom';

import { Navigate } from 'react-router-dom';

import UserListContainer from './../../../containers/admin/users/index-container';
import CreateContainer from './../../../containers/admin/users/create-container'; 
import ChangeDetailsContainer from './../../../containers/admin/users/auth/change-details-container';
import BatchCreateContainer from './../../../containers/admin/users/batch-create-container';
import Errors from './../../common/errors';
import DashboardContainer from './../../../containers/admin/users/dashboard-container';
import SettingsContainer from './../../../containers/admin/users/auth/change-details-container';
import PageListContainer from './../../../containers/admin/pages/page-list-container';
import PageContainer from './../../../containers/admin/pages/page-container';
import MenuListContainer from '../../../containers/admin/menus/menu-list-container';
import MenuContainer from '../../../containers/admin/menus/menu-container';
import SlideshowsContainer from '../../../containers/admin/slideshows/slideshows-container';
import SignupsContainer from '../../../containers/admin/reports/signups-container';
import DonationsContainer from '../../../containers/admin/reports/donations-container';
import InstagramContainer from '../../../containers/admin/instagram/instagram-container';
import WordingContainer from '../../../containers/admin/wording/wording-container';
import RedirectContainer from '../../../containers/admin/redirects/redirect-container';
import ArticleListContainer from '../../../containers/admin/news-articles/article-list-container';
import ArticleContainer from '../../../containers/admin/news-articles/article-container';

function AdminManage(props){

    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }

    return (
        
        <div className="admin-container">

            <div className="content core-admin">
                <Routes>
                    <Route index element={<DashboardContainer />} />
                    <Route path='/dashboard' element={<DashboardContainer />} />
                    <Route exact path='/settings' element={<SettingsContainer />} />

                    <Route path='/pages' element={<PageListContainer />} />
                    <Route path='/pages/:pageSlug' element={<PageContainer />} />
                    <Route exact path='/pages/create' element={<PageContainer />} />

                    <Route path='/news-articles' element={<ArticleListContainer />} />
                    <Route path='/news-articles/:slug' element={<ArticleContainer />} />
                    <Route exact path='/news-articles/create' element={<ArticleContainer />} />

                    <Route path='/wording' element={<WordingContainer />} />
                    <Route path='/redirects' element={<RedirectContainer />} />

                    <Route path='/menus' element={<MenuListContainer />} />
                    <Route path='/menus/:slug' element={<MenuContainer />} />

                    <Route path='/slideshow' element={<SlideshowsContainer />} />

                    <Route path='/signups' element={<SignupsContainer />} />
                    <Route path='/donations' element={<DonationsContainer />} />

                    <Route path='/instagram' element={<InstagramContainer />} />
                    <Route path='/instagram/rdr' element={<InstagramContainer />} />

                    <Route path='/users' element={<UserListContainer />} />
                    <Route exact path='/users/upload' element={<BatchCreateContainer />} />
                    <Route path='/users/:userRef' element={<CreateContainer />} />
                    <Route path='/users/self' element={<ChangeDetailsContainer/>} />
                    <Route path='*' element={<Errors />} /> 
                </Routes>
            </div>
          
        </div>
    )
}

export default AdminManage