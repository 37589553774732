// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../../store/flash';

// View components
import Instagram from '../../../components/admin/instagram/instagram';

const InstagramContainer = (props) => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;

    const [error, setError] = useState(false);
    const [flashState, flashActions] = useFlash(); // eslint-disable-line no-unused-vars
    const [haveToken, setHaveToken] = useState(false);
    const [authCookie] = useCookies([process.env.REACT_APP_COOKIE]);

    const [btnTxt, setBtnTxt] = useState('Authorise Instagram');

    let loginUrl = "/admin";
    const navigate = useNavigate();

    const location = useLocation();
    const checkForCode = () => {

        // Extract the query string params from the redirect URL 
        var params = location.search;
        params = params.replace('?', '');
        params = params.split('&');
        var paramsArr = {};
        for (var i in params) {
            var param = params[i].split('=');
            paramsArr[param[0]] = param[1];
        }

        // If a code does not exist then return
        if (typeof paramsArr.code == "undefined") {
            checkExisting(); // Check if there is an existing token
            return;
        }

        // We have a code so use it to get the access token
        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            navigate(loginUrl);
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Call the server to generate the token in the back-end and store it
        fetch(process.env.REACT_APP_API_BASE + '/instagram/get-token/' + paramsArr.code, {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === "success") {
                setError({ msg: "The Instagram account has been connected successfully.", style: "grn" });
                setHaveToken(true);
                setBtnTxt("Update Instagram account");
                return
            }
            setError({ msg: "Sorry but there was an error generating the required token. Please refresh the page and try again.", style: "red" });

        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
                navigate(loginUrl);
            }
            setError({ msg: "Sorry but there was an error generating the required token. Please refresh the page and try again.", style: "red" });
            setBtnTxt('Authorise Instagram');
        });

    }

    // Check whether a token already exists in the database 
    const checkExisting = () => {

        // We have a code so use it to get the access token
        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            navigate(loginUrl);
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Call the server to generate the token in the back-end and store it
        fetch(process.env.REACT_APP_API_BASE + '/instagram/check-token', {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === "success") {
                setHaveToken(true);
                setBtnTxt("Update Instagram account");
                setError({ msg: "There is an existing link to your Instagram account.", style: "grn" });
                return
            }

        })
        .catch(function (error) {});
    }

    // Check that we have a partially complete auth status (based upon the cookie)
    let triggerRdr = () => {

        setBtnTxt('Redirecting...');

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            navigate(loginUrl);
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/instagram/auth-url', {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === "success") {
                window.location.href = data.detail;
                return;
            }
            setError({ msg: "Sorry but there was an error redirecting you. Please refresh the page and try again.", style: "red" });
            setBtnTxt('Authorise Instagram');

        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
                navigate(loginUrl);
            }
            setError({ msg: "Sorry but there was an error redirecting you. Please refresh the page and try again.", style: "red" });
            setBtnTxt('Authorise Instagram');
        });
    }

    /* eslint-disable */
    useEffect(() => {
        checkForCode();
    }, []);
    /* eslint-enable */

    return (
        <Instagram
            triggerRdr={triggerRdr}
            btnTxt={btnTxt}
            error={error}
            haveToken={haveToken}
        />
    );
}

export default InstagramContainer;