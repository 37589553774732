import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import moment from 'moment';

import Fetching from './../../utilities/fetching';
import Article from './../../components/latest/article';


function ArticleContainer(props) {

    // Set up the state to track status 
    const [current, setCurrent] = useState(false);
    const [latestArticles, setArticles] = useState(false);
    const [mostRecent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    // URL slugs
    let { slug } = useParams();

    const loadOther = () => {

        // Ste up the time that we want to go since 
        var dateTime = moment().format('YYYY-MM-DD HH:mm:ss');
        if (mostRecent) {
            dateTime = mostRecent;
        }
        dateTime = dateTime.replace(" ", "T").replace(/:/g, "C");

        // Make the request
        var reqHeaders = {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        };
        fetch(process.env.REACT_APP_API_BASE + '/latest/3/' + dateTime, {
            method: 'get',
            headers: reqHeaders
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {

                // Now that the data has been retrieved, set it to state for the component to display it
                if (data.rslt === "success") {
                    // Append the details to the array, don't just add them
                    if (latestArticles) {
                        var updatedArticles = [...latestArticles]
                        for (var i in data.detail) {
                            updatedArticles.push(data.detail[i]);
                        }
                        setArticles(updatedArticles);
                    } else {
                        setArticles(data.detail);
                    }
                } else {
                    setError(true);
                }
                setLoading(false)

            })
            .catch(function (error) {
                setLoading(false);
                // console.log('Request failed', error);
            });

    }

    // Load up the article content
    const loadContent= () => {

        // Make the request
        var reqHeaders = {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        };
        fetch(process.env.REACT_APP_API_BASE + '/latest/news/' + slug, {
            method: 'get',
            headers: reqHeaders
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {

                // Now that the data has been retrieved, set it to state for the component to display it
                if (data.rslt === "success") {
                    // Append the details to the array, don't just add them
                    setCurrent(data.detail);

                } else {
                    setError(true);
                }
                setLoading(false)

            })
            .catch(function (error) {
                setLoading(false);
                // console.log('Request failed', error);
            });

    }

    // Trigger the load on page load
    /* eslint-disable */
    useEffect(() => {

        loadContent();
        loadOther();

    }, []);
    /* eslint-enable */

    return (
        <Article 
            loading={loading}
            latestArticles={ latestArticles }
            current={ current }
            error={ error }
        />
    );
}

export default ArticleContainer;
