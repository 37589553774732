import moment from 'moment';

// Components
// import Masonry from 'react-masonry-css';
import LatestArticleItem from './latest-article-item';
// import LatestTweetItem from './latest-tweet-item';
// import LatestFbItem from './latest-fb-item';

// Assets
import FamilyImage from './../../assets/img/children/family.jpg';
import Header from '../common/header';
import DisplayImageContainer from '../../containers/images/display-image-container';

function Article(props) {

    if (!props.current) {
        return <p>Loading...</p>
    }

    console.log(props.current)

    return (
        <article className="content article">
            <Header page="latest" />

            <div className="width-8 centred">
                
                {typeof props.current.Image !== 'undefined' && props.current.Image.id > 0 ? (
                    <DisplayImageContainer
                        imageId={props.current.Image.id}
                        size="full"
                        class="header-img"
                        alt={props.current.Title + " main image"}
                    />

                ) : (
                    <img src={FamilyImage } alt="Family" className="header" />
                )}
                        

                <h1 className="article-header">{ props.current.Title }</h1>
                <h6>{moment(props.current.PublishDate).format('Do MMMM YYYY')} <span>&loz;</span> Written by { props.current.Author }</h6>

                <div className="article-body" dangerouslySetInnerHTML={{ __html: props.current.Content}}></div>
            </div>

            <div className="width-12 centred">
                <div className="other-articles">
                    <h4>Other articles</h4>

                    { props.latestArticles ? (
                        <div>
                            {props.latestArticles.map((article, i) =>
                                <LatestArticleItem key={ "other-" + i } minTxt={true} article={article.NewsArticle} />
                            )}
                        </div>
                    ) : (
                        <p>Loading...</p>
                    )}
                    
                </div>
            </div>
        </article>
    );
}

export default Article;
