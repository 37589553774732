import { Link } from 'react-router-dom';
import ExtractContent from '../../../utilities/extract-content'; 

import Trolley from './../../../assets/img/icons/trolley.png';
import Hand from './../../../assets/img/icons/hand.png';
import Noise from './../../../assets/img/icons/make-noise.png';
import ArticleSml from '../../latest/article-sml';
import NewsArticlesContainer from '../../../containers/blocks/sub-blocks/news-articles-container';

function HalfNews(props) {

    let content = ExtractContent.block(props.block);

    return (
        <div className="two-col news other-help">

                <section className="width-half help-ways">
                    <div className="half-content">
                        <h5>Other ways to get involved</h5>
                        <article className="other-way">
                            <img src={Trolley} alt="Trolley icon" />
                            <div>
                                <h6>{ content["title-1"] }</h6>
                                <p>{content["content-1"]}</p>
                                { typeof content["btn-1-lnk"] != "undefined" && typeof content["btn-1-txt"] != "undefined" && content["btn-1-lnk"] !== "" && content["btn-1-txt"] !== "" &&
                                    <Link to={ content["btn-1-lnk"] }>{ content["btn-1-txt"] }</Link>
                                }
                            </div>
                        </article>
                        <article className="other-way">
                            <img src={Hand} alt="Helping hand icon" className="hand" />
                            <div>
                                <h6>{content["title-2"]}</h6>
                                <p>{ content["content-2"] }</p>
                                { typeof content["btn-2-lnk"] != "undefined" && typeof content["btn-2-txt"] != "undefined" && content["btn-2-lnk"] !== "" && content["btn-2-txt"] !== "" &&
                                    <Link to={ content["btn-2-lnk"] }>{ content["btn-2-txt"] }</Link>
                                }
                            </div>
                        </article>
                        <article className="other-way">
                            <img src={Noise} alt="Make some noise icon" />
                            <div>
                                <h6>{content["title-3"]}</h6>
                                <p>{content["content-3"]}</p>
                                { typeof content["btn-3-lnk"] != "undefined" && typeof content["btn-3-txt"] != "undefined" && content["btn-3-lnk"] !== "" && content["btn-3-txt"] !== "" &&
                                    <Link to={ content["btn-3-lnk"] }>{ content["btn-3-txt"] }</Link>
                                }
                            </div>
                        </article>
                    </div>
                </section>

                <section className="width-half light-red-bg">
                    <div className="half-content">
                        <h5>Latest news</h5>

                        <NewsArticlesContainer limit={ 5 } />
                    </div>
                </section>
        </div>
    );
}

export default HalfNews;
