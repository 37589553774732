import { Link } from 'react-router-dom';
import ExtractContent from '../../../utilities/extract-content'; 
import DisplayImageContainer from '../../../containers/images/display-image-container';

function BannerCta(props) {

    let content = ExtractContent.block(props.block);

    return (
        <section className="banner cta">
            {content['img'] > 0 &&
                <DisplayImageContainer
                    imageId={content["img"]}
                    size="half"
                    class="banner-inner"
                    isBgImage={true}
                />
            }

            <div className="content">
                <div className="width-7 centred">
                    <h4>{ content.title }</h4>
                    <div dangerouslySetInnerHTML={{ __html: content.content }}></div>
                    {typeof content["btn-txt"] != "undefined" && typeof content["btn-lnk"] != "undefined" &&
                        <Link className="btn sml" to={content["btn-lnk"]}>{content["btn-txt"]}</Link>
                    }
                </div>
            </div>
        </section>
    );
}

export default BannerCta;
