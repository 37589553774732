// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


// Utilities
import Fetching from '../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../../store/flash';

// View components
import Menu from './../../../components/admin/menus/menu';

const MenuContainer = (props) => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [error, setError] = useState(false);
    const [flashState, flashActions] = useFlash();
    const [authCookie] = useCookies(['csirt']);

    const [menuPages,setMenuPages] = useState(false);
    const [menuPageOptions,setMenuPageOptions] = useState(false)
    const [loading,toggleLoading] = useState(true);
    const [menuId,setMenuId] = useState(-1)
    const [isSaving,toggleIsSaving] = useState(false)

    const navigate = useNavigate();

    let loginUrl = "/admin";

    let location = useLocation();
    let menuSlug = location.pathname.replace('/admin/menus/', '');

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadMenu = () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the Menu you are looking for. Please sign in and try again.", style: "red" });
            navigate("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/pages/menu/'+menuSlug, {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            let menuPages = [];

            data.detail.menuPages.forEach((menu) => {
                menu.page.parentId = menu.parentId;
                menuPages.push(menu.page)
            })
            setMenuId(data.detail.id);

            setMenuPages( menuPages);
            console.log(menuPages);

        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the Menu you are looking for. PLease sign in and try again.", style: "red" });
            }
            navigate(loginUrl);
        });
    }

    let loadAvailableMenuPages = () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the Menu you are looking for. Please sign in and try again.", style: "red" });
            navigate("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/pages', {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {


            let pageOptions = [];

            for(let i in data.detail){
                let pageInMenu = false;
                for(let j in menuPages){
                    if(menuPages[j].id === data.detail[i].id){
                        pageInMenu = true;
                    }
                }

                if(!pageInMenu){
                    pageOptions.push(data.detail[i])
                }
            }

            setMenuPageOptions(pageOptions)
            // setHasInitialised(true);

        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the Menu you are looking for. Please sign in and try again.", style: "red" });
            }
            // navigate(loginUrl);
        });
    }

  

    const movePage = (dragIndex, hoverIndex,isDragInMenuList,isInMenuList, isChild, page) => {
 
        console.log("--- NEW DRAG ---");
        console.log('dragindex',dragIndex);
        console.log('hoverIndex',hoverIndex);
        console.log('isDragInMenuList',isDragInMenuList);
        console.log('isInMenuList', isInMenuList);
        console.log('isChild', isChild);
        console.log('Page',page);

        //prevent reorder bug triggering
        if(dragIndex === undefined  || hoverIndex === undefined){
            return;
        }

        let newMenuPages = menuPages.map(a => ({...a}));
        let newMenuPageOptions = menuPageOptions.map(a => ({...a}));
        let draggedOption

        if(isDragInMenuList && isDragInMenuList === isInMenuList){
            //reordering menu list
            newMenuPages= array_move(newMenuPages,hoverIndex,dragIndex);
            newMenuPages = handleChild(newMenuPages,page,isChild);
            newMenuPages = sortChildren(newMenuPages);
            console.log(newMenuPages);
            setMenuPages(newMenuPages)
        } else if (!isDragInMenuList &&  isDragInMenuList === isInMenuList){
            //reordering menu option list
            newMenuPageOptions= array_move(newMenuPageOptions,hoverIndex,dragIndex)
            setMenuPageOptions(newMenuPageOptions)
        } else if (!isDragInMenuList && isInMenuList){
            //Dragging from option to list
            draggedOption = newMenuPageOptions[dragIndex]; 
            newMenuPageOptions.splice(dragIndex,1)
            newMenuPages.splice(hoverIndex,0,draggedOption)
            newMenuPages = handleChild(newMenuPages, page, isChild)
            newMenuPages = sortChildren(newMenuPages);
            setMenuPages(newMenuPages)
            setMenuPageOptions(newMenuPageOptions)
        } else if (isDragInMenuList && !isInMenuList){
            //Dragging from list to options
            draggedOption = newMenuPages[dragIndex]; 
            newMenuPages.splice(dragIndex,1)
            newMenuPageOptions.splice(hoverIndex,0,draggedOption)
            newMenuPages = sortChildren(newMenuPages);
            setMenuPages(newMenuPages)
            setMenuPageOptions(newMenuPageOptions)
        }
        
    };

    const savePageReorder = () => {

        toggleIsSaving(true)
        let data = {
            "menuSlug":menuSlug,
            "menuId":menuId,
            "pages":[]
        }

        menuPages.forEach((page,i) => {
            data.pages.push({
                pageId:page.id,
                sortOrder:i+1,
                parentId:page.parentId
            })
        })

        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Run the save itself 
        fetch(process.env.REACT_APP_API_BASE + '/menu', {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
            body:JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            toggleIsSaving(false)
            if(data.rslt === 'success'){
                flashActions.set({ msg: "The menu list has been successfully updated.", style: "grn" });
            }
            

        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

    };

    // Work out whether we need an update in the parent ID of the element being moved
    const handleChild = (mps, page, isChild) => {
  
        // If this is not marked as a child tne remove it's parent ID
        if (!isChild) {
            for (var i in mps) {
                if (page.id === mps[i].id) {
                    if (page.parentId > 0) {
                        mps[i].parentId = 0;
                    }
                    break;
                }
            }
            return mps;
        }

        // If it is a child then give it a parent ID
        var lastParent = 0;
        for (var j in mps) {
            if (page.id === mps[j].id) {
                // Figure out what the parent is
                mps[j].parentId = lastParent;
                break;
            }
            // If this is a tp-level page, then it can be the last parent
            if (mps[j].parentId === 0) {
                lastParent = mps[j].id;
            }
        }
        return mps;

    }

    // Handle the case where a parent page has been reordered (and hence the kids need to be updated)
    const sortChildren = (mps) => {

        // Loop through the pages and set up an object that lists the children of each page
        var children = {};
        for (var i in mps) {
            if (mps[i].parentId > 0) {
                if (children[mps[i].parentId] === undefined) {
                    children[mps[i].parentId] = [];
                }
                children[mps[i].parentId].push(mps[i]);
            }
        }

        // Now loop through the menu pages again and make sure th children are in the right order
        var newMps = [];
        for (var j in mps) {
            if (mps[j].parentId === 0) {
                newMps.push(mps[j]);
                if (children[mps[j].id] !== undefined) {
                    for (var k in children[mps[j].id]) {
                        newMps.push(children[mps[j].id][k]);
                    }
                }
            }
        }

        return newMps;
    }

    const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };


    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        
        loadMenu();
        
    }, [menuSlug]);
    /* eslint-enable */

    /* eslint-disable */
    useEffect(() => {
        if (flashState.msg) {
            setError(flashState);
            flashActions.clear();
        }
    }, [setError, flashState, flashActions]);

    useEffect(() => {
        if(loading ){
            //have pages for menu loaded into state, but not retrieved the options
            if(!menuPageOptions && menuPages){
                //load the other options
                loadAvailableMenuPages();
            } else if(menuPageOptions && menuPages){
                //menu page options and menu pages loaded into state
                //so toggle loading off
                toggleLoading(false);
            } 
            
        }
        
    },[loading,menuPageOptions,menuPages]);
    /* eslint-enable */


    let createTierPage = (menuSlug ==='create') ? true : false;

    return (
        <Menu
            menuPages={menuPages}
            createTierPage={createTierPage}
            error={error}
            menuPageOptions={menuPageOptions}
            movePage={movePage}
            savePageReorder={savePageReorder}
            loading={loading}
            isSaving={isSaving}
            {...props}
        />
    );
}

export default MenuContainer;