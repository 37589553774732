import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import MenusContainer from '../../containers/pages/menus-container';
import Logo from './../../assets/img/core/esuubi-logo.webp';

function HeaderSlides(props) {

    const [menuOpen, setMenuOpen] = useState(false);

    const [firstSlideloaded, setFirstSlideLoaded] = useState(false)
    const toggleMenu = (e) => {
        e.preventDefault();
        setMenuOpen(!menuOpen);
    }

    let imgUrl = props.current.imgUrl;

    useEffect(() => {
        if(typeof imgUrl !== "undefined"){
            const img = new Image();
            img.src = imgUrl;

            img.onload = () => {
                setFirstSlideLoaded(true);
            };
        }

    }, [imgUrl]);

    return (
        <section className="leader with-slides">
            <header>
                <div className="logo left">
                    <a href="/">
                        <img src={Logo} alt="Esuubi logo" />
                    </a>
                </div>
                <div className="response right">
                    <button className="menu-toggle" onClick={(e) => toggleMenu(e)}>Menu</button>
                    <MenusContainer menuSlug="secondary" />
                </div>
                <nav className={menuOpen ? "width-6 centred open" : "width-6 centred"}>
                    <MenusContainer menuSlug="main" />
                </nav>
            </header>

            <section className="slideshow">
                {props.slides.map((slide, k) =>
                    <div className={ props.current.id === slide.id ? "slide current" : (typeof props.prev.id != "undefined" && props.prev.id === slide.id) ? "slide prev" : "slide" } key={"slide-" + k} style={{opacity : !firstSlideloaded ? 0 : 1, backgroundImage: "url('" + slide.imgUrl + "')" }}>
                        <div className="width-10 centred overlay">
                            { slide.title !== "" &&
                                <h3>{ slide.smallTitle }</h3>
                            }
                            <h2>{ slide.title }</h2>
                            { slide.intro !== "" &&
                                <p>{ slide.intro }</p>
                            }
                            {slide.link !== "" && slide.linkText !== "" &&
                                <Link to={slide.link} className="btn">{slide.linkText}</Link>
                            }
                        </div>
                    </div>
                )}
            </section>
        </section>
    );
}

export default HeaderSlides;

/*
<div className="width-4 description">
                    { props.slides.map( (slide, k) =>
                        <div key={"desc-" + k} className={ props.current && slide.id === props.current.id ? "desc-content active" : "desc-content"}>
                            <h2>{ slide.title }</h2>
                            { slide.intro !== "" &&
                                <p>{ slide.intro }</p>
                            }
                            { slide.link !== "" && slide.linkText !== "" &&
                                <Link to={ slide.link } className="pseudo-btn">{ slide.linkText }</Link>
                            }
                        </div>
                    )}

                    <ul className="dots">
                        { props.slides.map( (slide, i) => 
                            <Fragment key={'dot-' + i}>
                                { props.current && slide.id === props.current.id ? (
                                    <li className="current"><button onClick={() => props.moveSlide(i)}></button></li>
                                ) : (
                                    <li><button onClick={() => props.moveSlide(i)}></button></li>
                                )}
                            </Fragment>
                        )}
                    </ul>
                </div>

                <div className="width-8 imgs">
                    {props.slides.map((slide, j) => 
                        <div key={"img-" + j} className={(props.loadedFirstImg && props.current && slide.id === props.current.id) ? "ss-img active" : "ss-img"}>
                            <img src={slide.imgUrl } alt={slide.title} onLoad={ () => { if (j === 0) props.setLoadedFirstImg(true) }} />
                        </div>
                    )}
                </div>
*/