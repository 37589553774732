import React, { Fragment } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import GA4React from "ga-4-react";

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'

import RoutingContainer from './containers/routing-container';
import AuthRoutingContainer from './containers/auth-routing-container';
import PagesContainer from './containers/pages/pages-container';
import PaymentsContainer from './containers/payments/donations-container';
import GiftAidContainer from './containers/payments/giftaid-container';
import Errors from './components/common/errors';
import PageTemplateContainer from './containers/page-template-container';
import LatestContainer from './containers/latest/latest-container';
import LatestRouting from './containers/latest/latest-routing';
import FullPageError from './components/common/full-page-error';


function App() {
  return (
    <Fragment>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <Routes>
            
            <Route path='/u/*' element={<RoutingContainer/>} />
            <Route path='/login' element={<RoutingContainer/>} />
            <Route path='/admin/*' element={<AuthRoutingContainer isAdmin={true} />} />

            <Route exact path="/p/donate" element={<PaymentsContainer />} />
            <Route path="/gift-aid" element={<GiftAidContainer />} />
            <Route path="/p/donate/:urlAmount" element={<PaymentsContainer />} />
            <Route path="/" element={<PageTemplateContainer />}>
              <Route path="/latest/:slug" element={<LatestRouting />} />
              <Route exact path="/latest" element={<LatestContainer />} />
              <Route path="/p/:slug" element={<PagesContainer />} />
              <Route index element={<PagesContainer />} />
            </Route>
            <Route path='/*' element={<FullPageError />} />

            <Route element={<Errors />} /> 
          </Routes>
        </BrowserRouter>
      </DndProvider>
    </Fragment>
  );
}

try {
  setTimeout(_ => {
    const ga4react = new GA4React("G-R0RGSKHBH4");
    ga4react.initialize().catch(err => console.error(err));
  }, 4000);
} catch (err) {
  console.error(err);
}

export default App;
