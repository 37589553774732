import ExtractContent from '../../../utilities/extract-content'; 

import Trolley from './../../../assets/img/icons/trolley.png';
import Hand from './../../../assets/img/icons/hand.png';
import Noise from './../../../assets/img/icons/make-noise.png';
import ArticleSml from '../../latest/article-sml';
import EditableButton from '../../admin/common/forms/editable-button';
import NewsArticlesContainer from '../../../containers/blocks/sub-blocks/news-articles-container';

function HalfNewsAdmin(props) {

    let content = ExtractContent.block(props.block);

    return (
        <div className="two-col news other-help">

                <section className="width-half help-ways">
                    <div className="half-content">
                        <h5><input type="text" className="white mtop-big" placeholder="Title..." defaultValue={content["title"]} onInput={(e) => props.updateField("title", e.target.value)} onBlur={() => props.saveBlock()} /></h5>
                        <article className="other-way">
                            <img src={Trolley} alt="Trolley icon" />
                            <div>
                                <h6><input type="text" className="white mtop-big" placeholder="Title..." defaultValue={content["title-1"]} onInput={(e) => props.updateField("title-1", e.target.value)} onBlur={() => props.saveBlock()} /></h6>
                                <p><input type="text" className="white mtop-big" placeholder="Content..." defaultValue={content["content-1"]} onInput={(e) => props.updateField("content-1", e.target.value)} onBlur={() => props.saveBlock()} /></p>

                                <EditableButton
                                    btnTxt={typeof content["btn-1-txt"] !== "undefined" ? content["btn-1-txt"] : "Enter link text..."}
                                    fieldName="btn-1-txt"
                                    fieldNameLnk="btn-1-lnk"
                                    btnLnk={typeof content["btn-1-lnk"] !== "undefined" ? content["btn-1-lnk"] : ""}
                                    updateField={props.updateField}
                                    saveBlock={props.saveBlock}
                                />
                            </div>
                        </article>
                        <article className="other-way">
                            <img src={Hand} alt="Helping hand icon" className="hand" />
                            <div>
                                <h6><input type="text" className="white mtop-big" placeholder="Title..." defaultValue={content["title-1"]} onInput={(e) => props.updateField("title-2", e.target.value)} onBlur={() => props.saveBlock()} /></h6>
                                <p><input type="text" className="white mtop-big" placeholder="Content..." defaultValue={content["content-1"]} onInput={(e) => props.updateField("content-2", e.target.value)} onBlur={() => props.saveBlock()} /></p>

                                
                                <EditableButton
                                    btnTxt={typeof content["btn-2-txt"] !== "undefined" ? content["btn-2-txt"] : "Enter link text..."}
                                    fieldName="btn-2-txt"
                                    fieldNameLnk="btn-2-lnk"
                                    btnLnk={typeof content["btn-2-lnk"] !== "undefined" ? content["btn-2-lnk"] : ""}
                                    updateField={props.updateField}
                                    saveBlock={props.saveBlock}
                                />
                            </div>
                        </article>
                        <article className="other-way">
                            <img src={Noise} alt="Make some noise icon" />
                            <div>
                                <h6><input type="text" className="white mtop-big" placeholder="Title..." defaultValue={content["title-3"]} onInput={(e) => props.updateField("title-3", e.target.value)} onBlur={() => props.saveBlock()} /></h6>
                                <p><input type="text" className="white mtop-big" placeholder="Content..." defaultValue={content["content-3"]} onInput={(e) => props.updateField("content-3", e.target.value)} onBlur={() => props.saveBlock()} /></p>

                                
                                <p>TODO: Insert social media icons</p>
                            </div>
                        </article>
                    </div>
                </section>

                <section className="width-half light-red-bg">
                    <div className="half-content">
                        <h5>Latest news</h5>
                        <NewsArticlesContainer limit={ 5 } />
   
                    </div>
                </section>
        </div>
    );
}

export default HalfNewsAdmin;
