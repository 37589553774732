import { useState, useEffect } from "react";

// Use cookies 
import { useCookies } from 'react-cookie';
import CryptoJS from 'crypto-js';

// Components
import AdminArticleContainer from "../admin/latest/article-container";
import ArticleContainer from "./article-container";

function LatestRouting(props) {

    const [auth, setAuth] = useState(false);
    const [authCookie] = useCookies([process.env.REACT_APP_COOKIE]);
    let encSecret = process.env.REACT_APP_ENC_KEY;

    // Check whether the user is signed in as an admin
    const loadAuthState = () => {

        // Check for a cookie
        if (authCookie && Object.keys(authCookie).length > 0 && typeof authCookie[process.env.REACT_APP_COOKIE] != "undefined" && typeof authCookie[process.env.REACT_APP_COOKIE] !== "undefined") {

            var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
            reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

            setAuth(reformData);

        }

    }

    /* eslint-disable */
    useEffect(() => {
        loadAuthState();
    }, []);
    /* eslint-enable */

    // If they are an admin return the admin view 
    if (auth) {
        return (
            <AdminArticleContainer auth={auth} />
        );
    }

    return (
        <ArticleContainer />
    );
}

export default LatestRouting;
