// TODO: Handling CSRF attacks?!?!
// Submit an MFA token to complete login

// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../../store/flash';

// View components
import ArticleList from '../../../components/admin/news-articles/article-list';


const ArticleListContainer = (props) => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [error, setError] = useState(false);
    const [flashState, flashActions] = useFlash();
    const [authCookie] = useCookies([process.env.REACT_APP_COOKIE]);

    const [articles,setArticles] = useState([]);
    const [page, setPage] = useState(1);
    const [isComplete, setIsComplete] = useState(false);
    const [loading, toggleIsLoading] = useState(false)

    const navigate = useNavigate();

    let loginUrl = "/admin";

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadArticles = (force) => {

        if (isComplete || loading) return;

        if(page === 1 ){
            toggleIsLoading(true);
        }
        
        let p = page;
        if (force) {
            p = 1;
            setArticles([]);
        }

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined") {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the articles you are looking for. PLease sign in and try again.", style: "red" });
            navigate("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/news-articles/page/' + p, {
            method: 'get',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if (data.rslt === 'success' ) {
                // Append the new articles to the existing ones if the page is greater than 0
                if (p > 1) {
                    setArticles(articles.concat(data.detail));
                } else {
                    setArticles(data.detail);
                }
                setPage(p + 1);
                if (data.detail.length < 25) {
                    setIsComplete(true);
                }
            }
            toggleIsLoading(false)
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the articles you are looking for. Please sign in and try again.", style: "red" });
                navigate(loginUrl);
            }
            toggleIsLoading(false);
        });
    }

    // Watch th page scrolling 
    // When it gets within 150px of the bottom, load more articles 
    let justTriggered = false;
    window.onscroll = function(ev) {
        if (!justTriggered && (window.innerHeight + window.scrollY) >= document.body.offsetHeight - 150) {
            justTriggered = true;
            loadArticles();
            setTimeout(() => { justTriggered = false; }, 1000);
        }
    };

    // Delete a page
    const publishToggle = ( e, pageSlug ) => {

        e.preventDefault();
        setError(false);

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined") {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the article you are looking for. PLease sign in and try again.", style: "red" });
            navigate("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/news-articles/publish/' + pageSlug, {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if ( typeof data.rslt != "undefined" && data.rslt === "success" ) {
                setError({ msg: "Your article has been successfully updated.", style: "grn" });
                loadArticles(true);
                scrollToTop();
                return
            }
            
            setError({ msg: "There was an error updating the article. Please try again.", style: "red" });
            scrollToTop();
            return;

        })
        .catch(function (error) {
            setError({ msg: "There was an error updating the article. Please try again.", style: "red" });
            scrollToTop();
        });

    }

    // Scroll the page to the top 
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }
  

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        loadArticles();        
    }, []);
    /* eslint-enable */

    // useEffect to load up the auth check when the component mounts
    useEffect(() => {
        if (flashState.msg) {
            setError(flashState);
            flashActions.clear();
        }
    }, [setError, flashState, flashActions]);
// 
    // let createArticlePage = (articleSlug ==='create') ? true : false;

    return (
        <ArticleList 
            loading={loading} 
            articles={articles} 
            publishToggle={publishToggle} 
            error={error} 
            {...props} 
        />
    );
}

export default ArticleListContainer;