import ExtractContent from '../../../utilities/extract-content';

import Family from './../../../assets/img/icons/family.png';
import Loved from './../../../assets/img/icons/loved.png';
import Water from './../../../assets/img/icons/water.png';

function Aims(props) {

    let content = ExtractContent.block(props.block);

    return (
        <section className="aims">
            <div className="third">
                <img src={ Loved } alt="Loving care" />
                <h3>{ content["title-1"] }</h3>
                <p>{ content["content-1"] }</p>
            </div>
            <div className="third">
                <img src={Water} alt="Water" />
                <h3>{content["title-2"]}</h3>
                <p>{content["content-2"]}</p>
            </div>
            <div className="third">
                <img src={Family} alt="Nurturing family" />
                <h3>{content["title-3"]}</h3>
                <p>{content["content-3"]}</p>
            </div>
        </section>
    );
}

export default Aims;

// TODO: Update the images dynamically