import { Link } from 'react-router-dom';
import ExtractContent from '../../../utilities/extract-content';

import Heart from './../../../assets/img/icons/hand-heart-big.png';
import Wallet from './../../../assets/img/icons/wallet.png';
import Shopping from './../../../assets/img/icons/shopping.png';
import DisplayImageContainer from '../../../containers/images/display-image-container';
import { Fragment } from 'react';

function PanelThree(props) {
  
    let content = ExtractContent.block(props.block);
  
    return (
        <div className="help">
            <div className="content">
                <section className="width-8 centred">
                    <h5>How you can help</h5>
                </section>
                <section className="width-12 options">
                    <div className="third">
                        {typeof content["lnk-1"] !== "undefined" && typeof content["lnk-txt-1"] !== "undefined" &&
                            <Fragment>  
                                {content["lnk-1"].indexOf("http") > -1 ? (
                                    <a href={content["lnk-1"]} target='_blank' rel="noreferrer">{content["lnk-txt-1"]}</a>
                                ) : (
                                    <Link to={content["lnk-1"]}>{content["lnk-txt-1"] }</Link>
                                )}
                            </Fragment>
                        }

                        {content['img-1'] > 0 &&
                            <DisplayImageContainer
                                imageId={content["img-1"]}
                                size="third"
                                class="help-img"
                                isBgImage={true}
                            />
                        }

                        <div>
                            <img src={Heart} alt="Heart in hand icon" />
                            <h5>{content["title-1"]}</h5>
                            <p>{ content["content-1"] }</p>
                            {typeof content["lnk-1"] !== "undefined" && typeof content["lnk-txt-1"] !== "undefined" &&
                                <Link to={content["lnk-1"]}>{content["lnk-txt-1"]}</Link>
                            }
                        </div>
                    </div>
                    <div className="third">
                        { typeof content["lnk-2"] !== "undefined" && typeof content["lnk-txt-2"] !== "undefined" &&
                            <Fragment>  
                                {content["lnk-1"].indexOf("http") > -1 ? (
                                    <a href={content["lnk-2"]} target='_blank' rel="noreferrer">{content["lnk-txt-2"]}</a>
                                ) : (
                                    <Link to={content["lnk-2"]}>{content["lnk-txt-2"] }</Link>
                                )}
                            </Fragment>
                        }
                        
                        {content['img-2'] > 0 &&
                            <DisplayImageContainer
                                imageId={content["img-2"]}
                                size="third"
                                class="help-img"
                                isBgImage={true}
                            />
                        }

                        <div>
                            <img src={Wallet} alt="Heart in hand icon" />
                            <h5>{content["title-2"]}</h5>
                            <p>{content["content-2"]}</p>
                            {typeof content["lnk-2"] !== "undefined" && typeof content["lnk-txt-2"] !== "undefined" &&
                                <Link to={content["lnk-2"]}>{content["lnk-txt-2"]}</Link>
                            }
                        </div>
                    </div>
                    <div className="third">
                        {typeof content["lnk-3"] !== "undefined" && typeof content["lnk-txt-3"] !== "undefined" &&
                            <Fragment>  
                                {content["lnk-3"].indexOf("http") > -1 ? (
                                    <a href={content["lnk-3"]} target='_blank' rel="noreferrer">{content["lnk-txt-3"]}</a>
                                ) : (
                                    <Link to={content["lnk-3"]}>{content["lnk-txt-3"] }</Link>
                                )}
                            </Fragment>
                        }

                        {content['img-3'] > 0 &&
                            <DisplayImageContainer
                                imageId={content["img-3"]}
                                size="third"
                                class="help-img"
                                isBgImage={true}
                            />
                        }

                        <div>
                            <img src={Shopping} alt="Heart in hand icon" />
                            <h5>{ content["title-3"] }</h5>
                            <p>{content["content-3"]}</p>
                            {typeof content["lnk-3"] !== "undefined" && typeof content["lnk-txt-3"] !== "undefined" &&
                                <Link to={content["lnk-3"]}>{content["lnk-txt-3"]}</Link>
                            }
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default PanelThree;
