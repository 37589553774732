// Core react and plugins
import React, { useState } from 'react';

// Components
import Fetching from '../../../../utilities/fetching';
import ArticleBar from '../../../../components/admin/latest/cms/article-bar';


// Hook itself
const ArticleBarContainer = (props) => {

    const [publishClass, setPublishClass] = useState("");
    const [deleteClass, setDeleteClass] = useState("");


    // Handle the publication of a block
    const publishArticle = () => {

        setPublishClass("");

        // Run the save itself 
        fetch(process.env.REACT_APP_API_BASE + '/news-articles/content/publish/'+props.current.Slug, {
            method: 'post',
            headers: {
                "Content-type": "application/json",
                'api-token': props.authState.apiToken,
                'api-key': props.authState.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if (typeof data.rslt == "undefined" || data.rslt !== "success") {
                setPublishClass("error");
                alert("Sorry but there was an error publishing your changes. Please try again.");
                return;
            }
            setPublishClass("success");
            setTimeout(function(){
                setPublishClass("");
            }, 5000);
        })
        .catch(function (error) {
            alert("Sorry but there was an error publishing your changes. Please try again.");
            console.log('Request failed', error);
        });

    }

    const discardArticleEdits = () => {


        const doDiscard = window.confirm("Do you wish to discard these changes?");

        if(!doDiscard){
            return false;
        }

        setDeleteClass("");

        // Run the save itself 
        fetch(process.env.REACT_APP_API_BASE + '/news-articles/content/discard/'+props.current.Slug, {
            method: 'post',
            headers: {
                "Content-type": "application/json",
                'api-token': props.authState.apiToken,
                'api-key': props.authState.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if (typeof data.rslt == "undefined" || data.rslt !== "success" ) {
                setDeleteClass("error");
                alert("Sorry but there was an error discarding your changes. Please try again.");
                return;
            }

            props.loadContent()
            setDeleteClass("success");
            setTimeout(function(){
                setDeleteClass("");
            }, 5000);
        })
        .catch(function (error) {
            alert("Sorry but there was an error publishing your changes. Please try again.");
            console.log('Request failed', error);
        });

    }


    // Return the view
    return (
        <ArticleBar 
            publishArticle={ publishArticle }
            publishClass={ publishClass }
            deleteClass={ deleteClass }          
            page={ props.page }
            article={props.current}
            blockBarItems={props.blockBarItems}           
            discardArticleEdits={discardArticleEdits}
            actions={props.actions}
        />
    );
}

export default ArticleBarContainer;