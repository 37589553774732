// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../../store/flash';

// View components
import Slideshow from '../../../components/admin/slideshows/slideshows';

const SlideshowsContainer = (props) => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;

    const [error, setError] = useState(false);
    const [flashState, flashActions] = useFlash(); // eslint-disable-line no-unused-vars
    const [authCookie] = useCookies([process.env.REACT_APP_COOKIE]);

    const [slides, setSlides] = useState([]);
    const [selectedKey, setSelectedKey] = useState([]);
    const [saveTxt, setSaveTxt] = useState("Save slideshow");

    const [managerStatus, setManagerStatus] = useState(false);


    const navigate = useNavigate();

    let loginUrl = "/admin";

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadImages = () => {

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/slideshows/home', {
            method: 'GET',
            headers: {
                "Content-type": "application/json"
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setSlides(data.detail);

        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            }
            navigate(loginUrl);
        });
    }

    const saveSlides = ( e ) => {

        e.preventDefault();
        setSaveTxt("Saving slideshow...");
        setError(false);

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the article you are looking for. PLease sign in and try again.", style: "red" });
            navigate("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));
   
        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/slideshow/home', {
            method: 'post',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
            body: JSON.stringify(slides)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setSaveTxt("Save slideshow");
            if (data.rslt === "success") {
                setError({ msg: "The slideshow has been updated successfully.", style: "grn" });
                return;
            }

            setError({ msg: "There was an error saving the page. Please check you have provided an image and title for each slide, then try again.", style: "red" });

        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page.", style: "red" });
            }
            // navigate(loginUrl);
            setSaveTxt("Save slideshow");
        });

    }

    // Add a new slide
    const addSlide = (e) => {

        e.preventDefault();

        var newSlides = [...slides];

        // Work out what the new sort order should be
        var highestOrder = 0;
        for (var i in newSlides) {
            if (newSlides[i].sortOrder > highestOrder) {
                highestOrder = newSlides[i].sortOrder;
            }
        }

        // Set up the new slide 
        let newSlide = {
            id: 0,
            sortOrder: highestOrder + 1,
            imageId: false,
            title: "",
            smallTitle: "",
            intro: "",
            link: "",
            linkText: ""
        }
        newSlides.push(newSlide);
        setSlides(newSlides);

    }

    // Delete a slide - requires submission to save it
    const deleteSlide = (key) => { 

        if (!window.confirm("Are you sure you want to delete this slide? You will need to save the changes to confirm the deletion.")) {
            return;
        }

        var newSlides = [];
        var counter = 1;
        for (var i in slides) {
            if (parseInt(i) !== key) {
                var slide = slides[i];
                slide.sortOrder = counter;
                newSlides.push(slides[i]);
                counter++;
            }
        }
        setSlides(newSlides);

        setError({ msg: "The slide has been removed on the screen. Once the slidesshow is saved, it will be removed form the slideshow itself.", style: 'grn' });

    }

    const toggleImageManager = (_, _size, fieldKey) => {
        setSelectedKey(fieldKey);
        let newStatus = !managerStatus;
        setManagerStatus(newStatus);
    }

    // Select the image and close the image manager
    const selectImage = (image, fieldKey) => {
        updateSlide(fieldKey, 'imageId', image.id);
        toggleImageManager(false, '');
    }

    // Update the slides entries 
    const updateSlide = (key, fieldName, val) => {

        // Set up the slides for update 
        var newSlides = [...slides];

        // Find the slide that needs updating
        for (var i in newSlides) {
            if (parseInt(i) === key) {
                newSlides[i][fieldName] = val;
            }
        }
    
        // Update the slides entry
        setSlides(newSlides);
        console.log(newSlides);

    }

    // Move slide in the sort order 
    const moveSlide = (key, direction) => {

        var newSlides = [...slides];

        // Figure out the direction
        if (direction === "up") {

            // Loop through the slides and increment the chosen slide up, then then previous one down
            var prevOrder = 0;
            for (var i in newSlides) {
                if (parseInt(i) === key - 1) {
                    prevOrder = newSlides[i].sortOrder + 1;
                    newSlides[i].sortOrder = prevOrder;
                }
                if (parseInt(i) === key) {
                    newSlides[i].sortOrder = prevOrder - 1;
                    break;
                }
            }

        } else {

            // For a down move, increase the sort order and decrease the order of the slide below
            var order = 0;
            for (var j in newSlides) {
                if (parseInt(j) === key) {
                    newSlides[j].sortOrder = newSlides[j].sortOrder + 1;
                    order = newSlides[j].sortOrder;
                }
                if (parseInt(j) === key + 1) {
                    newSlides[j].sortOrder = order - 1;
                    break;
                }
            }
        }

        // Sort the array based upon sort order 
        newSlides.sort(function(a, b) {
            return a.sortOrder - b.sortOrder;
        });
    
        // Update the slides entry
        setSlides(newSlides);
        console.log(newSlides);

    }

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        loadImages();  
    }, []);
    /* eslint-enable */

    return (
        <Slideshow 
            requiredSize="slide"
            slides={slides}
            addSlide={ addSlide }
            updateSlide={ updateSlide }
            toggleImageManager={ toggleImageManager }
            selectImage={ selectImage }
            showManager={ managerStatus }
            selectedKey={ selectedKey }
            saveTxt={ saveTxt }
            saveSlideshow={ saveSlides }
            error={ error }
            deleteSlide={ deleteSlide }
            moveSlide={ moveSlide }
        />
    );
}

export default SlideshowsContainer;