// Core react and plugins
import React, { useState, useEffect } from 'react';

// Utilities
import Fetching from '../../../utilities/fetching';

// View components
import Wording from '../../../components/common/wording/wording';


const WordingContainer = (props) => {

    // State management
    const [wording,setWording] = useState("");
    const [cachedWording,setCachedWording] = useState({});

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadWording = () => {

        // Check wheether the cached wording already exists 
        if (typeof cachedWording[props.wordName] !== "undefined") {
            setWording(cachedWording[props.wordName]);
            return;
        }

        // Get the wording from the server
        fetch(process.env.REACT_APP_API_BASE + '/public/wording/' + props.wordName, {
            method: 'get',
            headers: {
                "Content-type": "application/json"
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            setWording(data.detail);
            setCachedWording({ ...cachedWording, [props.wordName]: data.detail });
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        loadWording();        
    }, []);
    useEffect(() => {
        loadWording();
    }, [props.wordName]);
    /* eslint-enable */

    return (
        <Wording 
            wording={wording}
            {...props}
        />
    );
}

export default WordingContainer;