// Core react and plugins
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Components
import Error from './../common/forms/error';
import Loader from './../common/loader';
import PageSidebar from '../common/page-sidebar';

// Component class itself
class ArticleList extends Component {

    componentDidMount() {
        document.title = 'Manage pages';
    }

    render() {

        if (this.props.loading) {
            return <Loader />;
        }

        return (

            <Fragment>
                <PageSidebar />

                <div className="width-9">
                    <Link to="/admin/news-articles/create" className="btn right">Create article</Link>
                    <h1>Manage news articles</h1>

                    <Error error={this.props.error} />

                    <div className='account-forms'>
                        <table className="network">
                            <thead>
                                <tr>
                                    <th>Article title</th>
                                    <th>Slug</th>
                                    <th>Author</th>
                                    <th>Created</th>
                                    <th>Published</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!this.props.articles || this.props.articles.length === 0 ? (
                                    <tr>
                                        <td colSpan="5"><em>Nothing to display</em></td>
                                    </tr>
                                ) : (
                                    <Fragment>
                                        {this.props.articles.map((article, i) =>
                                            <Fragment key={'page-' + i}>
                                                <tr>
                                                    <td className="">
                                                        <Link to={"/latest/" + article.Slug} dangerouslySetInnerHTML={{ __html: article.Title }}></Link>
                                                    </td>
                                                    <td className="">
                                                        {article.Slug}
                                                    </td>
                                                    <td className="">
                                                        {article.User.FirstName} {article.User.LastName}
                                                    </td>
                                                    <td className="">
                                                        {moment(article.Created).format('DD/MM/YYYY')}
                                                    </td>
                                                    <td className="">
                                                        {article.PublishDate != null ? moment(article.PublishDate).format('DD/MM/YYYY') : '-'}
                                                    </td>
                                                    <td className="">
                                                        <Link to={"/admin/news-articles/" + article.Slug}>Edit</Link>
                                                        <button className="action-btn" onClick={(e) => this.props.publishToggle(e, article.Slug)}>{ article.Published ? "Unpublish" : "Publish" }</button>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        )}
                                    </Fragment>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

            </Fragment>

        );

    }
}

export default ArticleList;