// Core react and plugins
import React from 'react';
import ArticleSml from '../../../latest/article-sml';
import Loader from '../../../admin/common/loader';

function NewsArticles(props) {

    if (props.loading || typeof props.articles === "undefined" || !props.articles) {
        return (
            <Loader />
        )
    }

    return (
        <div className="articles">
            {props.articles.map((article, i) =>
                <ArticleSml article={article} key={"art-" + i} />
            )}
        </div >
    )

}

export default NewsArticles;