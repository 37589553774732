import ExtractContent from '../../../utilities/extract-content';

import Donate from './../../../assets/img/children/donation.jpg';
import Sponsor from './../../../assets/img/children/sponsor.jpg';
import Family from './../../../assets/img/children/family.jpg';
import Heart from './../../../assets/img/icons/hand-heart-big.png';
import Wallet from './../../../assets/img/icons/wallet.png';
import Shopping from './../../../assets/img/icons/shopping.png';
import EditableButton from '../../admin/common/forms/editable-button';
import EditImgContainer from '../../../containers/admin/blocks/edit-img-container';

function PanelThreeAdmin(props) {
  
    let content = ExtractContent.block(props.block);
  
    return (
        <div className="help">
            <div className="content centred">
                <section className="width-8 centred">
                    <h5><input type="text" className="white mtop-big" placeholder="Title..." defaultValue={content["title"]} onInput={(e) => props.updateField("title", e.target.value)} onBlur={() => props.saveBlock()} /></h5>
                </section>
                <section className="width-12 options">
                    <div className="third">
                        <EditImgContainer
                            fieldName="img-1"
                            requiredSize="third"
                            image={content["img-1"]}
                            updateField={props.updateField}
                            imageId={content["img-1"]}
                        />

                        <div>
                            <img src={Wallet} alt="Heart in hand icon" />
                            <h5><input type="text" className="white mtop-big" placeholder="Title..." defaultValue={content["title-1"]} onInput={(e) => props.updateField("title-1", e.target.value)} onBlur={() => props.saveBlock()} /></h5>
                            <p><input type="text" className="white mtop-big" placeholder="Content..." defaultValue={content["content-1"]} onInput={(e) => props.updateField("content-1", e.target.value)} onBlur={() => props.saveBlock()} /></p>
                            
                            <EditableButton
                                btnTxt={typeof content["lnk-txt-1"] !== "undefined" ? content["lnk-txt-1"] : "Enter link text..."}
                                fieldName="lnk-txt-1"
                                fieldNameLnk="lnk-1"
                                btnLnk={typeof content["lnk-1"] !== "undefined" ? content["lnk-1"] : ""}
                                updateField={props.updateField}
                                saveBlock={props.saveBlock}
                            />
                        </div>
                    </div>
                    <div className="third">
                        <EditImgContainer
                            fieldName="img-2"
                            requiredSize="third"
                            image={content["img-2"]}
                            updateField={props.updateField}
                            imageId={content["img-2"]}
                        />
                        <div>
                            <img src={Heart} alt="Heart in hand icon" />
                            <h5><input type="text" className="white mtop-big" placeholder="Title..." defaultValue={content["title-2"]} onInput={(e) => props.updateField("title-2", e.target.value)} onBlur={() => props.saveBlock()} /></h5>
                            <p><input type="text" className="white mtop-big" placeholder="Content..." defaultValue={content["content-2"]} onInput={(e) => props.updateField("content-2", e.target.value)} onBlur={() => props.saveBlock()} /></p>
                            
                            <EditableButton
                                btnTxt={typeof content["lnk-txt-2"] !== "undefined" ? content["lnk-txt-2"] : "Enter link text..."}
                                fieldName="lnk-txt-2"
                                fieldNameLnk="lnk-2"
                                btnLnk={typeof content["lnk-2"] !== "undefined" ? content["lnk-2"] : ""}
                                updateField={props.updateField}
                                saveBlock={props.saveBlock}
                            />
                        </div>
                    </div>
                    <div className="third">
                        <EditImgContainer
                            fieldName="img-3"
                            requiredSize="third"
                            image={content["img-3"]}
                            updateField={props.updateField}
                            imageId={content["img-3"]}
                        />
                        <div>
                            <img src={Shopping} alt="Heart in hand icon" />
                            <h5><input type="text" className="white mtop-big" placeholder="Title..." defaultValue={content["title-3"]} onInput={(e) => props.updateField("title-3", e.target.value)} onBlur={() => props.saveBlock()} /></h5>
                            <p><input type="text" className="white mtop-big" placeholder="Content..." defaultValue={content["content-3"]} onInput={(e) => props.updateField("content-3", e.target.value)} onBlur={() => props.saveBlock()} /></p>
                            
                            <EditableButton
                                btnTxt={typeof content["lnk-txt-3"] !== "undefined" ? content["lnk-txt-3"] : "Enter link text..."}
                                fieldName="lnk-txt-3"
                                fieldNameLnk="lnk-3"
                                btnLnk={typeof content["lnk-3"] !== "undefined" ? content["lnk-3"] : ""}
                                updateField={props.updateField}
                                saveBlock={props.saveBlock}
                            />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default PanelThreeAdmin;
