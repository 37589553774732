// Checks a user's auth status and forwards them on accordingly

// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Utilities
import Fetching from './../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import useAuth from '../store/auth';
import useFlash from '../store/flash';
// import useBookmarks from '../store/bookmarks';
import { useCookies } from 'react-cookie';

// View components
import AuthRouting from '../components/auth-routing';

const AuthRoutingContainer = (props) => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [loading, setLoading] = useState(true);
    const [pause, setPause] = useState(false); // eslint-disable-line
    // const [statePath, setStatePath] = useState(false);
    const [authState, setAuth] = useAuth(false); // eslint-disable-line
    const [flashState, flashActions] = useFlash(); // eslint-disable-line
    const [authCookie, setCookie, removeCookie] = useCookies([process.env.REACT_APP_COOKIE]); // eslint-disable-line
    let curPath = useLocation(); // eslint-disable-line
    let navigate = useNavigate(); // eslint-disable-line

    // Upon mount check that the user is not already logged in
    // eslint-disable-next-line
    let checkStatus = () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry, but we could not find the page you are looking for. Please sign in and try again.", style: 'red' });
            navigate("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/users/me', {
            method: 'get',
            headers: {
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (typeof data.Email != "undefined") {

                // Update the global auth state with the relevant info
                setAuth.storeSession({
                    apiToken: reformData.apiToken,
                    apiKey: reformData.apiKey,
                    client:data.Client,
                    email:data.Email,
                    firstName:data.FirstName,
                    lastName:data.LastName,
                    manager:data.Manager,
                    mobileNo:data.MobileNo,
                    permissions:data.Permissions,
                    ref:data.Ref
                });

                // TODO: Handle permissions checks as part of the load of the individual pages
                // This allows us to redirect back from those pages, rather than try to redirect back onto yourself
                // It also means that the permissions checks can be carried out on the server-side, minimising round trips to the server

                setLoading(false);
                setPause(false);
                return;
            } 

            // Invalid token - clear the cookie and redirect to the login page
            removeCookie(process.env.REACT_APP_COOKIE, { path: '/', secure: true, sameSite: true });
            flashActions.set({ msg: "Sorry, but we could not find the page you are looking for. Please sign in and try again.", style: 'red' });
            navigate("/");
            
        })
        .catch(function (error) {
            // Invalid token - clear the cookie and redirect to the login page
            flashActions.set({ msg: "Sorry, but we could not find the page you are looking for. Please sign in and try again.", style: 'red' });
            removeCookie(process.env.REACT_APP_COOKIE, { path: '/', secure: true, sameSite: true });
            navigate("/");
        });
    }

    /* eslint-disable */
    useEffect(() => {
        setPause(true);
        checkStatus();
    }, []);
    /* eslint-enable */

    return (
        <AuthRouting
            loading={ loading }
            { ...props }
        />
    );
}

export default AuthRoutingContainer;
