import React, { Component, Fragment } from 'react';
import Footer from './footer';

class Errors extends Component {

    render() {

        return (
            <Fragment>
                <div className="error-screen">
                    <div className="width-6 centred">
                        <h1>Page not found</h1>
                        <p>Sorry, but it seems the page you&rsquo;re looking for either moved or never existed.</p>
                        <p>Please go back and try again</p>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );

    }
}

export default Errors;