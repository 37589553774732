// Core react and plugins
import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Checkbox from '../common/forms/checkbox';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Components
import Error from '../common/forms/error';
import ValidationError from '../common/forms/validation-error';
import Loader from '../common/loader';


// Component class itself
class Article extends Component {

    render() {

        const {
            title,intro,slug,isPublished
        } = this.props;

        if (this.props.loading) {
            return <Loader />;
        }
        let saveBtnText= 'Update Article';
        let savingBtnText = 'Updating Article...';
        let pageTitle = 'Update Article';

        if(this.props.createTierPage){
            saveBtnText= 'Create Article';
            savingBtnText = 'Creating Article...';
            pageTitle = 'Create Article';
        }

        return (

            <div className="width-6 centred">
                <Helmet>
                    <title>Manage pages :: Esuubi</title>
                </Helmet>

                <h1>{pageTitle}</h1>

                <Error error={this.props.error} />

                <form className='news-article'>
                    <div className="input text">
                        <label htmlFor="title">Title</label>
                        <input type="text" id="title" value={title}   onChange={(e) => this.props.setTitle(e.target.value)} />
                        <ValidationError errors={this.props.valErrors} fieldName="title" />
                    </div>
                    <div className="input text">
                        <label htmlFor="intro">Article intro</label>
                        <input type="text" id="intro" value={intro} onChange={(e) => this.props.setIntro(e.target.value)} />
                        <ValidationError errors={this.props.valErrors} fieldName="intro" />
                    </div>

                    <div className='greyed-sxn'>
                        <h6>Behind the scenes</h6>

                        <div className="input text">
                            <label htmlFor="slug">Slug</label>
                            <input type="text" id="slug" value={slug} onChange={(e) => this.props.setSlug(e.target.value)} />
                            <ValidationError errors={this.props.valErrors} fieldName="slug" />
                        </div>

                        <div className="input date">
                            <label htmlFor="startDate">Publication date</label>
                            <DatePicker selected={ this.props.publicationDate} onChange={(date) => this.props.setPublicationDate(date)} />
                        </div>

                        <div className="input text">
                            <label htmlFor="metaDesc">Meta description</label>
                            <input type="text" id="metaDesc" value={this.props.metaDesc} onChange={(e) => this.props.setMetaDesc(e.target.value)} />
                            <ValidationError errors={this.props.valErrors} fieldName="metaDesc" />
                        </div>

                        <div className="input text">
                            <label htmlFor="metaKw">Meta keywords</label>
                            <input type="text" id="metaKw" value={this.props.metaKw} onChange={(e) => this.props.setMetaKw(e.target.value)} />
                            <ValidationError errors={this.props.valErrors} fieldName="metaKw" />
                        </div>

                    </div>

                    <Checkbox label='Article is published' id='isPublished' checked={isPublished} handleChange={(e) => this.props.updatePublished("", e)} />

                    <button className='icon-edit-btn' onClick={(e) => this.props.updateNewsArticle(e)}>{this.props.isSaving ? savingBtnText : saveBtnText}</button>
                </form>
            </div>
        )

    }
}

export default Article;