// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../../store/flash';

// View components
import Article from '../../../components/admin/news-articles/article';

const ArticleContainer = (props) => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;

    const [error, setError] = useState(false);
    const [flashState, flashActions] = useFlash();
    const [authCookie] = useCookies(['csirt']);

    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [intro, setIntro] = useState('');
    const [metaDesc, setMetaDesc] = useState('');
    const [metaKw, setMetaKw] = useState('');
    const [publicationDate,setPublicationDate] = useState("")
    const [imageId, setImageId] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [slug,setSlug] = useState('');
    const [linkClass,setLinkClass] = useState('');
    const [template,setTemplate] = useState(-1);
    const [isSaving,toggleIsSaving] = useState(false)
    const [valErrors,setValErrors] = useState(false);
    const [managerStatus, setManagerStatus] = useState(false);
    const [requiredSize, setRequiredSize] = useState(false);


    const navigate = useNavigate();

    let loginUrl = "/admin";

    let location = useLocation();
    let articleSlug = location.pathname.replace('/admin/news-articles/', '');
    if(articleSlug !== 'create'){
        articleSlug = '-fs-' + articleSlug; 
    }

    const updatePublished = (_, e) => {
        setIsPublished(e.target.checked);
    }

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadArticle = () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            navigate("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/news-articles/'+articleSlug+'?edit=1', {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            setTitle(data.detail.Title);
            setSlug(data.detail.Slug)
            setIntro(data.detail.Intro)
            setMetaDesc(data.detail.MetaDesc)
            setMetaKw(data.detail.MetaKeywords)
            setIsPublished(data.detail.Published)
            setPublicationDate(data.detail.PublishDate)
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            }
            navigate(loginUrl);
        });
    }

    const updateNewsArticle = ( e ) => {

        e.preventDefault();
        toggleIsSaving(true);
        setError(false);
        setValErrors({})

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the article you are looking for. PLease sign in and try again.", style: "red" });
            navigate("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        let url

        if( typeof articleSlug !== 'undefined' && articleSlug !== 'create'){
            url = process.env.REACT_APP_API_BASE + '/news-articles/'+articleSlug;
        } else {
            url = process.env.REACT_APP_API_BASE + '/news-articles'
        }
         
        let data = {
            "slug": slug,
            "title": title,
            "content":"",
            "intro": intro,
            "metaDesc": metaDesc,
            "metaKeywords": metaKw,
            "template":template,
            "isPublished": isPublished,
            "publishDate":publicationDate
        }
   
        // Check the server side to see what the status of this auth is...
        fetch(url, {
            method: 'post',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
            body: JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if ( typeof data.rslt != "undefined" && data.rslt === 'success' ) {
                navigate("/admin/news-articles");
                flashActions.set({ msg: "The news article has been successfully saved.", style: "grn" });
                toggleIsSaving(false);
                return;
            } else if (data.rslt === 'error'){
                setValErrors(data.messages);
            } else if (data.rslt === 'invalid-data'){
                let validationErrors = {}
                for(let fieldName in data.detail){
                    let validationMessages = [data.detail[fieldName]];
                    validationErrors[fieldName.toLowerCase()] = validationMessages
                }
                setValErrors(validationErrors)
            }
            
            setError({ msg: "There was an error saving the news article. Please try again.", style: "red" });
            toggleIsSaving(false);
            return;

        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the news article.", style: "red" });
            }
            // navigate(loginUrl);
            toggleIsSaving(false);
            return;
        });

    }

    const clearImage = () => {
        setImageId(0);
    }

    const toggleImageManager = (e, size) => {
        if (e) e.preventDefault();
        let newStatus = !managerStatus;
        setManagerStatus(newStatus);
        setRequiredSize(size);
    }

    // Select the image and close the image manager
    const selectImage = (image) => {
        setImageId(image.id);
        toggleImageManager(false, '');
    }

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        if(articleSlug !== undefined && articleSlug !=='create'){
            // console.log('here')
            loadArticle();
        }
        
    }, [articleSlug]);
    /* eslint-enable */

    // useEffect to load up the auth check when the component mounts
    useEffect(() => {
        if (flashState.msg) {
            setError(flashState);
            flashActions.clear();
        }
    }, [setError, flashState, flashActions]);


    let createTierPage = (articleSlug ==='create') ? true : false;

    return (
        <Article
            title={title}
            slug={slug}
            linkClass={linkClass}
            setLinkClass={setLinkClass}
            setSlug={setSlug}
            setTitle={setTitle}
            subTitle={subTitle}
            setSubTitle={setSubTitle}
            isPublished={isPublished}
            updatePublished={updatePublished}
            intro={intro}
            setIntro={setIntro}
            metaDesc={metaDesc}
            setMetaDesc={setMetaDesc}
            metaKw={metaKw}
            setMetaKw={setMetaKw}
            template={template}
            setTemplate={setTemplate}
            updateNewsArticle={updateNewsArticle}
            createTierPage={createTierPage}
            isSaving={isSaving}
            error={error}
            valErrors={valErrors}
            imageId={imageId}
            toggleImageManager={toggleImageManager}
            showManager={managerStatus}
            requiredSize={requiredSize}
            setRequiredSize={setRequiredSize}
            selectImage={selectImage}
            clearImage={clearImage}
            publicationDate={publicationDate}
            setPublicationDate={setPublicationDate}
            {...props}
        />
    );
}

export default ArticleContainer;