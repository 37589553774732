import ExtractContent from '../../../utilities/extract-content';

import Family from './../../../assets/img/icons/family.png';
import Loved from './../../../assets/img/icons/loved.png';
import Water from './../../../assets/img/icons/water.png';

function AimsAdmin(props) {

    let content = ExtractContent.block(props.block);

    return (
        <section className="aims centred">
            <div className="third">
                <img src={ Loved } alt="Loving care" />
                <h3><input type="text" className="white mtop-big" placeholder="Title..." defaultValue={content["title-1"]} onInput={(e) => props.updateField("title-1", e.target.value)} onBlur={() => props.saveBlock()} /></h3>
                <p><input type="text" className="white mtop-big" placeholder="Content..." defaultValue={content["content-1"]} onInput={(e) => props.updateField("content-1", e.target.value)} onBlur={() => props.saveBlock()} /></p>
            </div>
            <div className="third">
                <img src={Water} alt="Water" />
                <h3><input type="text" className="white mtop-big" placeholder="Title..." defaultValue={content["title-2"]} onInput={(e) => props.updateField("title-2", e.target.value)} onBlur={() => props.saveBlock()} /></h3>
                <p><input type="text" className="white mtop-big" placeholder="Content..." defaultValue={content["content-2"]} onInput={(e) => props.updateField("content-2", e.target.value)} onBlur={() => props.saveBlock()} /></p>
            </div>
            <div className="third">
                <img src={Family} alt="Nurturing family" />
                <h3><input type="text" className="white mtop-big" placeholder="Title..." defaultValue={content["title-3"]} onInput={(e) => props.updateField("title-3", e.target.value)} onBlur={() => props.saveBlock()} /></h3>
                <p><input type="text" className="white mtop-big" placeholder="Content..." defaultValue={content["content-3"]} onInput={(e) => props.updateField("content-3", e.target.value)} onBlur={() => props.saveBlock()} /></p>
            </div>
        </section>
    );
}

export default AimsAdmin;

// TODO: Update the images dynamically