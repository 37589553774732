// Core react and plugins
import React, { useState, useEffect } from 'react';

// Utilities
import Fetching from './../../utilities/fetching';
import DisplayImage from '../../components/images/display-image';

// View components
const DisplayImageContainer = (props) => {

    // State management
    const [image, setImage] = useState(false);

    // Get the image thumbnail link
    const getImage = () => {

        if (typeof props.imageId == "undefined" || props.imageId === "") {
            return;
        }

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/images-get/' + props.imageId + '/' + props.size, {
            method: 'GET',
            headers: {
                "Content-type": "application/json"
            }
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {

                if (data.rslt === 'success') {
                    setImage(data.detail);
                    if (typeof props.returnName != "undefined") {
                        props.returnName(data.detail.filename);
                    }
                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        getImage();
    }, [props.imageId]);
    /* eslint-enable */

    return (
        <DisplayImage
            image={image}
            {...props}
        />
    );
}

export default DisplayImageContainer;