import {Link} from 'react-router-dom';
import ExtractContent from '../../../utilities/extract-content';

function TxtCentre(props) {

    let content = ExtractContent.block(props.block);

    return (
        <div className={ typeof content.title != "undefined" && content.title !== "" ? "about" : "about sml-mrgn" }>
            <div className="content">
                <section className="width-8 centred">
                    { content.title !== "" &&
                        <h3>{ content.title }</h3>
                    }
                    {content.subtitle !== "" &&
                        <h4>{content.subtitle}</h4>
                    }
                    <div dangerouslySetInnerHTML={{ __html: content.content }}></div>
                    { typeof content["btn-txt"] != "undefined" && typeof content["btn-lnk"] != "undefined" &&
                        <Link className="btn sml" to={ content["btn-lnk"] }>{ content["btn-txt"] }</Link>
                    }
                </section>
            </div>
        </div>
    );
}

export default TxtCentre;
