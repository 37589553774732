import moment from 'moment';
import { Link } from 'react-router-dom';
import DisplayImageContainer from '../../containers/images/display-image-container';

function ArticleSml(props) {

    // Handle the intro
    var intro = props.article.NewsArticle.Intro.replace(/(<([^>]+)>)/gi, "");
    var ending = intro.slice(intro.length - 1);
    intro = intro.replace("&rsquo;", "'");
    if (ending !== "." && ending !== "?" && ending !== "!") {
        intro = intro + '...';
    }

    // Handle the title
    var title = props.article.NewsArticle.Title.replace("&#34;", '"').replace("&#34;", '"').replace("&quot;", '"').replace("&quot;", '"');

    return (
        
        <article className="news-item">
            <div>
            <Link className='full-lnk' to={ "/latest/" + props.article.NewsArticle.Slug }>{props.article.NewsArticle.Title}</Link>
                {props.article.NewsArticle.ImageID > 0 ? (
                    <DisplayImageContainer
                    imageId={props.article.NewsArticle.ImageID}
                    size="tmb"
                    class="article-icon"
                    alt={props.article.NewsArticle.Title + " thumbnail"}
                />
                ) : (
                    <span className='article-icon'></span>
                )}
                
                <p className="timestamp">{ moment(props.article.PublicationDate).format("DD/MM/YYYY") }</p>
                <h6>{ title }</h6>
                <p>{ intro }</p>
                <Link to={ "/latest/" + props.article.NewsArticle.Slug }>Read more</Link>
            </div>
        </article >
                            
    );
}

export default ArticleSml;
