// Core react and plugins
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Lightbox from '../../../admin/common/lightbox';


// Component class itself
// class BlockBar extends Component {
const ArticleBar = (props) => {

    const navigate = useNavigate();
    return (
        <Fragment>
            <Lightbox {...props} />
        
            <div className="cms-block-bar" ref={props.wrapperRef}>

                <ul>
                    {typeof props.actions !== "undefined" && props.actions.includes('cms') && (<li><button title="CMS Dashboard" className={"dashboard"} onClick={() => navigate("/admin/news-articles")}>CMS dashboard</button></li>)}
                    {typeof props.actions !== "undefined" && props.actions.includes('article-admin') && (<li><button title="Edit core article details" className={"edit"} onClick={() => navigate("/admin/news-articles/" + props.article.Slug)}>Edit page content</button></li>)}
                    {typeof props.actions !== "undefined" && props.actions.includes('discard') && (<li><button title="Discard Edits" className={"delete " + props.deleteClass} onClick={() => props.discardArticleEdits()}>Discard Edits</button></li>)}
                    {typeof props.actions !== "undefined" && props.actions.includes('publish') && (<li><button title="Publish changes" className={"publish " + props.publishClass} onClick={ () => props.publishArticle() }>Publish page updates</button></li>)}
                </ul>

            </div>
        </Fragment>
    );


}

export default ArticleBar;
