import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';

import Fetching from './../../utilities/fetching';
import LatestIndex from './../../components/latest/latest';


function LatestContainer() {

    // Set up the state to track status 
    const [latestArticles, setArticles] = useState(false);
    const laRef = useRef(false);
    const mostRecent = useRef(false);
    const blockFutureLoads = useRef(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const loadContent = () => {

        // Check whether we should block future loads
        if (blockFutureLoads && blockFutureLoads.current) {
            return;
        }

        // Ste up the time that we want to go since 
        var dateTime = moment().format('YYYY-MM-DD HH:mm:ss');
        if (mostRecent.current) {
            dateTime = mostRecent.current;
        }
        dateTime = dateTime.replace(" ", "T").replace(/:/g, "C");

        // Make the request
        var reqHeaders = {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        };
        fetch(process.env.REACT_APP_API_BASE + '/latest/25/' + dateTime, {
            method: 'get',
            headers: reqHeaders
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {

                // Now that the data has been retrieved, set it to state for the component to display it
                if (data.rslt === "success") {
                    // If there are less than 25 articles, block future loads
                    if (data.detail.length < 25) {
                        blockFutureLoads.current = true;
                    }

                    // Append the details to the array, don't just add them
                    if (laRef && laRef.current) {
                        var updatedArticles = [...laRef.current]
                        for (var i in data.detail) {
                            updatedArticles.push(data.detail[i]);
                        }
                        setArticles(updatedArticles);
                    } else {
                        setArticles(data.detail);
                    }
                } else {
                    setError(true);
                }
                setLoading(false)
                curLoading = false;

            })
            .catch(function (error) {
                setLoading(false);
                // console.log('Request failed', error);
            });

    }

    // Track the scrol position of the page to trigger the load
    let curLoading = false;
    const triggerListener = () => {
        window.onscroll = function () {
            // If within the last 500px of the bottom of the page, load more content
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 600) && !curLoading) {
                curLoading = true;
                loadContent();
            }
        };
    }

    // Trigger the load on page load
    /* eslint-disable */
    useEffect(() => {

        loadContent();
        setTimeout(() => {
            triggerListener();
        }, 1500);

    }, []);

    useEffect(() => {
        // Whent the latest articles are updated, updated the most recent date to the last article
        laRef.current = latestArticles;
        if (latestArticles) {
            var lastArticle = latestArticles[latestArticles.length - 1];
            // Subtract 10 seconds from the last article to ensure that we don't get the same article again + make sure it is in UTC
            var lastDate = moment(lastArticle.PublicationDate).subtract(10, 'seconds').utc().format('YYYY-MM-DD HH:mm:ss');
            mostRecent.current = lastDate;
        }
    }, [latestArticles]);
    /* eslint-enable */

    return (
        <LatestIndex 
            loading={loading}
            latestArticles={ latestArticles }
            error={ error }
        />
    );
}

export default LatestContainer;
