import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import CryptoJS from 'crypto-js';

import Fetching from './../../utilities/fetching';
import ExtractContent from './../../utilities/extract-content';
import Aims from '../../components/blocks/public/aims';
import AimsAdmin from '../../components/blocks/admin/aims';
import Banner from '../../components/blocks/public/banner';
import BannerAdmin from '../../components/blocks/admin/banner';
import BannerCtaAdmin from '../../components/blocks/admin/banner-cta';
import BannerCta from '../../components/blocks/public/banner-cta';
import HalfNewsAdmin from '../../components/blocks/admin/half-news';
import HalfNews from '../../components/blocks/public/half-news';
import TxtCentreAdmin from '../../components/blocks/admin/txt-centre';
import TxtCentre from '../../components/blocks/public/txt-centre';
import PanelThreeAdmin from '../../components/blocks/admin/panel-3';
import PanelThree from '../../components/blocks/public/panel-3';
import BannerSmlAdmin from '../../components/blocks/admin/banner-sml';
import BannerSml from '../../components/blocks/public/banner-sml';
import LeftImageAdmin from '../../components/blocks/admin/left-img';
import LeftImage from '../../components/blocks/public/left-img';
import RightImageAdmin from '../../components/blocks/admin/right-img';
import RightImage from '../../components/blocks/public/right-img';


// Import the blocks (admn and non-admin)



function BlocksContainer(props) {

    // Set up the state to track status 
    const [auth, setAuth] = useState(false);
    const [blockContent, setBlockContent] = useState({});
    const [error, setError] = useState(""); // eslint-disable-line no-unused-vars

    const [authCookie] = useCookies([process.env.REACT_APP_COOKIE]);
    let encSecret = process.env.REACT_APP_ENC_KEY;

    // Get the auth state from the cookies - to pass down
    const loadAuthState = () => {

        // Check for a cookie
        if (authCookie && Object.keys(authCookie).length > 0 && typeof authCookie[process.env.REACT_APP_COOKIE] != "undefined" && typeof authCookie[process.env.REACT_APP_COOKIE] !== "undefined") {

            var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
            reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

            setAuth(reformData);

        }

    }

    // Handle field updates from the forms
    const updateField = (fieldName, value, triggerSave = false) => {

        // Update the state
        var newContent = { ...blockContent }
        console.log(newContent);
        newContent[fieldName] = value;
        setBlockContent(newContent);
        // If this is omething that should trigger a save, then do so
        if (triggerSave) {
            saveBlock(fieldName, value);
        }

    }

    // Handle save of the block itself on blur of the fields
    const saveBlock = (fieldName, value) => {

        // Figure out the save data 
        var saveData = {
            blockId: props.block.id,
            content: blockContent
        }

        // If this is a directly triggered (e.g. CKEditor) save then don't rely on state (it updates asynchronously)
        if (typeof fieldName != "undefined" && typeof value != "undefined") {
            saveData.content[fieldName] = value;
        }

        // Run the save itself 
        fetch(process.env.REACT_APP_API_BASE + '/blocks/1', {
            method: 'post',
            headers: {
                "Content-type": "application/json",
                'api-token': auth.apiToken,
                'api-key': auth.apiKey
            },
            body: JSON.stringify(saveData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if (typeof data.rslt == "undefined" && data.rslt !== "success") {
                setError("Sorry but there was an error saving that change. Please try again.");
                return;
            }
            setError(false);
        })
        .catch(function (error) {
            setError("Sorry but there was an error saving that change. Please try again.");
            console.log('Request failed', error);
        });

    }

    // Get the menus for the header 
    const loadContent = () => {

        // CHECK THE AUTH STATE 
        var canEdit = false;
        if (authCookie && Object.keys(authCookie).length > 0 && typeof authCookie[process.env.REACT_APP_COOKIE] != "undefined" && typeof authCookie[process.env.REACT_APP_COOKIE] !== "undefined") {
            var authData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
            authData = JSON.parse(authData.toString(CryptoJS.enc.Utf8));
            if (authData.permissions.Manage) {
                canEdit = true;
            }
        }

        var content;
        if (canEdit) {
            content = ExtractContent.block(props.block, "draft");
        } else {
            content = ExtractContent.block(props.block);
        }
        setBlockContent(content);

    }

    // Trigger the load on page load
    /* eslint-disable */
    useEffect(() => {
        loadContent();
        loadAuthState();
    }, []);
    /* eslint-enable */

    // 
    if (auth && auth.permissions && auth.permissions.Manage) {

        // Set up the block options 
        const blockOpts = {
            "aims": <AimsAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "banner": <BannerAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "banner-cta": <BannerCtaAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "banner-sml": <BannerSmlAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "half-news": <HalfNewsAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "txt-centre": <TxtCentreAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "panel-3": <PanelThreeAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "left-img": <LeftImageAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "right-img": <RightImageAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
        }

        if ( typeof blockOpts[props.block.blockStyle] !== "undefined") {
            return blockOpts[props.block.blockStyle]
        }

    }

    // Set up the block options 
    const blockOptsPublic = {
        "aims": <Aims block={props.block} />,
        "banner": <Banner block={props.block} />,
        "banner-cta": <BannerCta block={props.block} />,
        "banner-sml": <BannerSml block={props.block} />,
        "half-news": <HalfNews block={props.block} />,
        "txt-centre": <TxtCentre block={props.block} />,
        "panel-3": <PanelThree saveBlock={saveBlock} updateField={updateField} block={props.block} />,
        "left-img": <LeftImage block={props.block} />,
        "right-img": <RightImage block={props.block} />,
    }

    if (typeof blockOptsPublic[props.block.blockStyle] !== "undefined") {
        return blockOptsPublic[props.block.blockStyle]
    }

    return "";
}

export default BlocksContainer;
