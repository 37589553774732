import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

import ExtractContent from '../../../utilities/extract-content';
import EditImgContainer from '../../../containers/admin/blocks/edit-img-container';
import EditableButton from '../../admin/common/forms/editable-button';

function LeftImage(props) {

    let content = ExtractContent.block(props.block, "draft");

    return (
        <div className="left-img">
            <div className="img-left-hang">
                <EditImgContainer
                    fieldName="img"
                    requiredSize="full"
                    image={content["img"]}
                    updateField={props.updateField}
                    imageId={content["img"]}
                />
            </div>
            <div className="content">
                <section className="width-6 right align-left written-content">
                    <div className="title-block">
                        <h3><input type="text" className="white mtop-big" placeholder="Title..." defaultValue={content["title"]} onInput={(e) => props.updateField("title", e.target.value)} onBlur={() => props.saveBlock()} /></h3>
                        <h4><input type="text" className="white mtop-big" placeholder="Sub-title..." defaultValue={content["subtitle"]} onInput={(e) => props.updateField("subtitle", e.target.value)} onBlur={() => props.saveBlock()} /></h4>
                    </div>
                    
                    <CKEditor
                        editor={InlineEditor}
                        data={content["content"] ? content["content"] : "Enter content..."}
                        config={{
                            toolbar: ['bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                        }}
                        onBlur={(event, editor) => {
                            const data = editor.getData();
                            props.updateField('content', data, true)
                        }}
                    />

                    <EditableButton
                        btnTxt={typeof content["btn-txt"] !== "undefined" ? content["btn-txt"] : "Enter link text..."}
                        fieldName="btn-txt"
                        fieldNameLnk="btn-lnk"
                        btnLnk={typeof content["btn-lnk"] !== "undefined" ? content["btn-lnk"] : ""}
                        updateField={props.updateField}
                        saveBlock={props.saveBlock}
                    />
                </section>
            </div>
        </div>
    );
}

export default LeftImage;
