// Core react and plugins
import React, { useState, useEffect, useRef } from 'react';

// Utilities
import Fetching from '../../utilities/fetching';

// View components
import HeaderSlides from '../../components/common/header-slides';

const HeaderSlidesContainer = (props) => {

    // State management
    const [slides, setSlides] = useState([]);
    const [current, setCurrent] = useState(false);
    const [prev, setPrev] = useState(false);
    const [loadedFirstImg, setLoadedFirstImg] = useState(false);
    const [slideInterval, setSlideInterval] = useState([]);
    const cur = useRef(current);

    const slideTime = 10000;

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadImages = () => {

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/slideshows/home', {
            method: 'GET',
            headers: {
                "Content-type": "application/json"
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setSlides(data.detail);
            if (typeof data.detail[0] != "undefined") {
                setCurrent(data.detail[0]);
            }

        })
        .catch(function (error) {
            console.log(error);
        });
    }

    // Get the resized image for the slide
    const getResizedImage = (i, slide) => {

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/images-get/' + slide.imageId + "/slide", {
            method: 'GET',
            headers: {
                "Content-type": "application/json"
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === "success") {
                let newSlides = [...slides];
                newSlides[i].imgUrl = data.detail.imgUrl;
                setSlides(newSlides);
            }

        })
        .catch(function (error) {
            console.log(error);
        });

    }

    // Start the slideshow running on timers 
    const startSlideshow = (isContinue = false) => {

        // Move to slide 0
        if (slideInterval) clearInterval(slideInterval)

        // Set up the interval
        var curSlide = 0;
        if (isContinue) {
            curSlide = isContinue;
        }

        // Trigger the automated slideshow
        var slideTotal = slides.length;
        var si = setInterval(function (){
            if (curSlide >= (slideTotal - 1)) {
                curSlide = 0;
            } else {
                curSlide++;
            }
            nextSlide(curSlide);
        }, slideTime);
        setSlideInterval(si);
    }

    // Next slide 
    // Figure out which slide we are currently on and move to the next one
    const nextSlide = (newKey) => {
        setPrev(cur.current);
        setCurrent(slides[newKey]);
    }

    // Handle the switch of the slideshow onto a different slide
    const moveSlide = (nextSlide, blockRestart = false) => {
        setPrev(cur.current);
        setCurrent(slides[nextSlide]);
        if (!blockRestart) {
            startSlideshow(nextSlide);
        }
    }

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        loadImages();
    }, []);
    useEffect(() => {
        if (slides.length > 0) {
            for (var i in slides) {
                if (typeof slides[i].imgUrl === "undefined") {
                    getResizedImage(i, slides[i]);
                }
            }
            startSlideshow();
        }
    }, [slides]);
    useEffect(() => {
        cur.current = current;
    }, [current]);
    /* eslint-enable */

    return (
        <HeaderSlides
            current={current}
            prev={prev}
            slides={slides}
            moveSlide={moveSlide}
            loadedFirstImg={loadedFirstImg}
            setLoadedFirstImg={setLoadedFirstImg}
        />
    );
}

export default HeaderSlidesContainer;