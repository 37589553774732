import { Link } from 'react-router-dom';
import Heart from './../../../assets/img/icons/hand-heart.png';
import DisplayImageContainer from '../../../containers/images/display-image-container';
import ExtractContent from '../../../utilities/extract-content';
import { Fragment } from 'react';

function BannerSml(props) {
    let content = ExtractContent.block(props.block);

    return (
        <section className="banner sml">
            {content['img'] > 0 &&
                <DisplayImageContainer
                    imageId={content["img"]}
                    size="half"
                    class="banner-inner"
                    isBgImage={true}
                />
            }

            <div className="content">
                <div className="width-9">
                    <img src={Heart} alt="Heart in hand" />
                    <h4>{content.content}</h4>
                </div>
                <div className="width-3">
                    {typeof content["action-txt-1"] != "undefined" && typeof content["action-link-1"] != "undefined" &&
                        <Fragment>
                            { content["action-link-1"].indexOf("http") > -1 ? (
                                <a className="btn sml" href={content["action-link-1"]} target='_blank' rel="noreferrer">{content["action-txt-1"]}</a>
                            ) : (
                                <Link className="btn sml" to={content["action-link-1"]}>{content["action-txt-1"]}</Link>
                            )}
                        </Fragment>
                    }
                </div>
            </div>
        </section>
    );
}

export default BannerSml;
