// Assets
import FamilyImage from './../../assets/img/children/family.jpg';
import moment from 'moment';

function LatestFbItem(props) {

    return (
        <div className="latest-item fb">
            <img src={ FamilyImage } alt="Family" /> 
            <div className="copy">
                <p className="datetime">{ moment(props.article.createdAt) }</p>
                <p>Nulla consectetur eleifend lacus et cursus. Praesent vestibulum convallis tellus a consectetur onec faucibus...</p>
                <a className="lnk" href={ props.article.permalink } target="_blank" rel="noreferrer">Read more</a>
            </div>
        </div>
    );
}

export default LatestFbItem;
