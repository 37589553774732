import Heart from './../../../assets/img/icons/hand-heart.png';
import EditImgContainer from '../../../containers/admin/blocks/edit-img-container';
import ExtractContent from '../../../utilities/extract-content';
import EditableButton from '../../admin/common/forms/editable-button';

function BannerSml(props) {

    let content = ExtractContent.block(props.block, "draft");

    return (
        <section className="banner sml">
            <EditImgContainer
                fieldName="img"
                requiredSize="full"
                image={content["img"]}
                updateField={props.updateField}
                imageId={content["img"]}
            />

            <div className="content">
                <div className="width-9">
                    <img src={Heart} alt="Heart in hand" />
                    <h4><input type="text" className="white mtop-big" placeholder="Content..." defaultValue={content["content"]} onInput={(e) => props.updateField("content", e.target.value)} onBlur={() => props.saveBlock()} /></h4>
                </div>
                <div className="width-3">
                    <EditableButton
                        btnTxt={typeof content["action-txt-1"] !== "undefined" ? content["action-txt-1"] : "Enter link text..."}
                        fieldName="action-txt-1"
                        fieldNameLnk="action-link-1"
                        btnLnk={typeof content["action-link-1"] !== "undefined" ? content["action-link-1"] : ""}
                        updateField={props.updateField}
                        saveBlock={props.saveBlock}
                    />
                </div>
            </div>
        </section>
    );
}

export default BannerSml;
