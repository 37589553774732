import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import moment from 'moment';

import Fetching from './../../../utilities/fetching';
import AdminArticle from '../../../components/admin/latest/article';
import { useCookies } from 'react-cookie';
import CryptoJS from 'crypto-js';

function AdminArticleContainer(props) {

    // Set up the state to track status 
    const [current, setCurrent] = useState(false);
    const [latestArticles, setArticles] = useState(false);
    const [mostRecent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [auth, setAuth] = useState(false);

    // URL slugs
    let { slug } = useParams();
    
    const [authCookie] = useCookies([process.env.REACT_APP_COOKIE]);
    let encSecret = process.env.REACT_APP_ENC_KEY;

    const loadAuthState = () => {

        // Check for a cookie
        if (authCookie && Object.keys(authCookie).length > 0 && typeof authCookie[process.env.REACT_APP_COOKIE] != "undefined" && typeof authCookie[process.env.REACT_APP_COOKIE] !== "undefined") {

            var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
            reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

            setAuth(reformData);

        }

    }

    const loadOther = () => {

        // Ste up the time that we want to go since 
        var dateTime = moment().format('YYYY-MM-DD HH:mm:ss');
        if (mostRecent) {
            dateTime = mostRecent;
        }
        dateTime = dateTime.replace(" ", "T").replace(/:/g, "C");

        // Make the request
        var reqHeaders = {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        };
        fetch(process.env.REACT_APP_API_BASE + '/latest/3/' + dateTime, {
            method: 'get',
            headers: reqHeaders
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {

                // Now that the data has been retrieved, set it to state for the component to display it
                if (data.rslt === "success") {
                    // Append the details to the array, don't just add them
                    if (latestArticles) {
                        var updatedArticles = [...latestArticles]
                        for (var i in data.detail) {
                            updatedArticles.push(data.detail[i]);
                        }
                        setArticles(updatedArticles);
                    } else {
                        setArticles(data.detail);
                    }
                } else {
                    setError(true);
                }
                setLoading(false)

            })
            .catch(function (error) {
                setLoading(false);
                // console.log('Request failed', error);
            });

    }

    // Load up the article content
    const loadContent= () => {

        // Make the request
        var reqHeaders = {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        };
        fetch(process.env.REACT_APP_API_BASE + '/latest/news/' + slug+'?edit=1', {
            method: 'get',
            headers: reqHeaders
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {

                // Now that the data has been retrieved, set it to state for the component to display it
                if (data.rslt === "success") {
                    // Append the details to the array, don't just add them
                    setCurrent(data.detail);

                } else {
                    setError(true);
                }
                setLoading(false)

            })
            .catch(function (error) {
                setLoading(false);
                // console.log('Request failed', error);
            });

    }

    // Update a field value
    const updateField = (field, value, save) => {

        // Update the state
        var updatedArticle = { ...current };
        updatedArticle[field] = value;
        setCurrent(updatedArticle);
        // Save the field
        if (save) {
            saveArticle(updatedArticle);
        }

    }


    // Save the article
    const saveArticle = (newContent) => {
        
        if(typeof newContent == "undefined"){
            newContent = current
        }
        // Figure out the save data 
        var saveData = {
            content:newContent.Content,
            author:newContent.Author,
            title:newContent.Title,
            image_id:newContent.ImageID
        }

        // Run the save itself 
        fetch(process.env.REACT_APP_API_BASE + '/news-articles/content/'+newContent.Slug, {
            method: 'post',
            headers: {
                "Content-type": "application/json",
                'api-token': auth.apiToken,
                'api-key': auth.apiKey
            },
            body: JSON.stringify(saveData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            console.log(data)
            if ( typeof data.rslt == "undefined" && data.rslt !== "success" ) {
                setError("Sorry but there was an error saving that change. Please try again.");
                return;
            }
            setError(false);
        })
        .catch(function (error) {
            setError("Sorry but there was an error saving that change. Please try again.");
            console.log('Request failed', error);
        });
    }

    // Trigger the load on page load
    /* eslint-disable */
    useEffect(() => {
        loadAuthState();
        loadContent();
        loadOther();

    }, []);
    /* eslint-enable */

    return (
        <AdminArticle 
            loading={loading}
            latestArticles={ latestArticles }
            current={ current }
            error={ error }
            loadContent={loadContent}
            saveArticle={ saveArticle }
            updateField={ updateField }
            authState={auth}
        />
    );
}

export default AdminArticleContainer;
