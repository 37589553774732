import { Fragment } from "react";

import Logo from "../../assets/img/core/esuubi-logo.webp";

function ErrorPage() {

    return (
        <Fragment>
            <div className="error-screen">
                <div className="width-6 centred">
                    <img src={Logo} alt="Esuubi Logo" />
                    <h1>Page not found</h1>
                    <p>Sorry, but it seems the page you&rsquo;re looking for either moved or never existed.</p>
                    <p>Please go back and try again</p>
                </div>
            </div>
        </Fragment>
    );
}

export default ErrorPage;
