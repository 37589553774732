// Core react and plugins
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

// Components
import Error from './../common/forms/error';
import Loader from './../common/loader';

// Component class itself
class MenuList extends Component {

    componentDidMount() {
        document.title = 'Manage Menus';
    }

    render() {

        if (this.props.loading) {
            return <Loader />;
        }

        return (

            <Fragment>

                <div className="width-3 sidebar">
                    <nav>
                        <ul>
                            <li><Link to="/admin/pages">Pages</Link></li>
                            <li><Link to="/admin/menus">Menus</Link></li>
                            <li><Link to="/admin/slideshow">Homepage slideshow</Link></li>
                        </ul>
                    </nav>
                </div>

                <div className="width-9">
                    <h1 style={{marginBottom:0}}>Manage Menus</h1>

                    <Error error={ this.props.error } />
                    <div className='account-forms'>
                        <table className="network">
                            <thead>
                                <tr>
                                    <th>Menu</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="">
                                        Main header menu
                                    </td>
                                    <td className="">
                                        <Link to={"/admin/menus/main"} className="action-btn">Edit</Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="">
                                        Secondary header menu
                                    </td>
                                    <td className="">
                                        <Link to={"/admin/menus/secondary"} className="action-btn">Edit</Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="">
                                        Footer menu
                                    </td>
                                    <td className="">
                                        <Link to={"/admin/menus/footer"} className="action-btn">Edit</Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </Fragment>

        );

    }
}

export default MenuList;