// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';

// View components
import Donations from '../../../components/admin/reports/donations';

const DonationsContainer = (props) => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;

    const [page, setPage] = useState(0);
    const [donations, setDonations] = useState([]);
    const [error, setError] = useState(false);
    const [detail, setDetail] = useState(false);
    const [authCookie] = useCookies([process.env.REACT_APP_COOKIE]);
    const limit = 25;

    const navigate = useNavigate();

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadDonations = () => {

        if (!page && page !== 0) {
            // There is nothing left to load
            return;
        }

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined") {
            // Not logged in so everything is cool
            navigate("/login");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        let existing = donations.length; 

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/reports/payments/' + existing + '/' + limit, {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success' && data.detail.length > 0) {
                var du = donations.concat(data.detail);
                setDonations(du);

                // Get the last ID to set the offset
                if (data.detail.length === limit) {
                    let lastItem = data.detail[data.detail.length - 1];
                    setPage(lastItem.id);
                } else {
                    setPage(false);
                }
                return;
            }

        })
        .catch(function (error) {
            navigate("/login");
            console.log(error);
        });
    }

    // Refund a donation 
    const refund = (id) => {

        if (!window.confirm("Are you sure you want to refund this donation?")){
            return;
        }

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined") {
            // Not logged in so everything is cool
            navigate("/login");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/reports/payments/refund/' + id, {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success') {
                // Get the last ID to set the offset
                fadeRow(id);
                setError({ msg: "The donation has been successfully refunded.", style: "grn" });
                return;
            }
            setError({msg: "Sorry but there was an error refunding the donation. Please try again.", style: "red"});

        })
        .catch(function (error) {
            navigate("/login");
            console.log(error);
        });
    }

    // Refund a donation 
    const cancel = (id) => {

        if (!window.confirm("Are you sure you want to cancel the capture of this donation?")){
            return;
        }

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined") {
            // Not logged in so everything is cool
            navigate("/login");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/reports/payments/cancel/' + id, {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success') {
                // Get the last ID to set the offset
                fadeRow(id);
                setError({ msg: "The donation capture has been successfully cancelled.", style: "grn" });
                return;
            }
            setError({msg: "Sorry but there was an error cancelling the donation capture. Please try again.", style: "red"});

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error cancelling the donation capture. Please try again.", style: "red" });
            console.log(error);
        });
    }

    const fadeRow = (id) => {

        var newDonations = [...donations];
        var index = newDonations.findIndex(x => x.id === id);
        newDonations[index].Refunded = newDonations[index].Amount;
        newDonations[index].Status = "refunded";
        setDonations(newDonations);

    }

    // Check that we have a partially complete auth status (based upon the cookie)
    let exportDonations = (e) => {
        e.preventDefault();

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined") {
            // Not logged in so everything is cool
            navigate("/login");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));


        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/reports/payments', {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success') {
                generateCsv(data.detail, "online-donations.csv");
            }

        })
        .catch(function (error) {
            navigate("/login");
            console.log(error);
        });
    }

    const handleScroll = (e) => {
        const bottom = (e.target.scrollHeight - e.target.scrollTop) >= (e.target.clientHeight - 20);
        if (bottom) {
            loadDonations();
        }
    }

    const generateCsv = (data, filename) => {
        var csv = "First name,Last name,Email address,Amount,Fees,Net amount received,Refunded amount,Can gift aid,Address 1,Address 2,Town/City,Postcode,Country,Provider,Created,Last modified \n";
        for (var i in data) {
            csv += data[i].FirstName + "," + data[i].LastName + "," + data[i].Email + ",";
            csv += "£" + (data[i].Amount / 100) + ",";
            csv += "£" + (data[i].Fees / 100) + ",";
            csv += "£" + (data[i].NetAmount / 100) + ",";
            csv += "£" + (data[i].Refunded / 100) + ",";
            csv +=  (data[i].GiftAid) ? "Yes," : "No,"; 
            csv += data[i].Address1 + ",";
            csv += data[i].Address2 + ",";
            csv += data[i].Address3 + ",";
            csv += data[i].Postcode + ",";
            csv += data[i].Country + ",";
            csv += data[i].Provider + ",";
            csv += data[i].CreatedAt + ",";
            csv += data[i].ModifiedAt + "\n";
        }
        downloadCSV(csv, filename);
    }

    const downloadCSV = (csv, filename) => {
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // Launch a lightbox to show the donation information 
    const launchDetail = (id) => {

        // Find the donation in the array with the correct ID
        var index = donations.findIndex(x => x.id === id);
        setDetail(donations[index]);

    }

    const closeDetail = (e) => {
        e.preventDefault();
        setDetail(false);
    }

    /* eslint-disable */
    useEffect(() => {
        loadDonations();
    }, []);
    /* eslint-enable */

    return (
        <Donations
            donations={donations}
            handleScroll={handleScroll}
            refund={refund}
            error={error}
            exportDonations={exportDonations}
            cancel={cancel}
            launchDetail={launchDetail}
            detail={detail}
            closeDetail={closeDetail}
        />
    );
}

export default DonationsContainer;