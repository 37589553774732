// Core React 
import Masonry from 'react-masonry-css';
import LatestArticleItem from './latest-article-item';
import Header from '../common/header';
// import LatestTweetItem from './latest-tweet-item';
import LatestFbItem from './latest-fb-item';
import ArticleBarContainer from '../../containers/admin/latest/cms/article-bar-container';
import Permissions from '../../utilities/permissions';

function LatestIndex(props) {

    // Set the articles array based upon the list
    var articles = [];
    for (var i in props.latestArticles) {
        if (props.latestArticles[i].newsId > 0) {
            articles.push(<LatestArticleItem key={ "news-" + i } article={ props.latestArticles[i].NewsArticle } />);
        }
        if (props.latestArticles[i].socialId > 0) {
            articles.push(<LatestFbItem key={ "fb-" + i } article={ props.latestArticles[i].SocialArticle } />);
        }
    }


    const breakpointsObj = {
        default: 3,
        900: 2,
        500: 1
    };


    return (
        <div className="content">
            <Header page="latest" />

            <h1 className="blank-header">Latest from Esuubi</h1>
        
            {props.authState && Permissions.actionCheck("menus", "crud", props.authState.permissions) &&
                <ArticleBarContainer actions={['cms']} {...props} />
            }

            <Masonry
                breakpointCols={breakpointsObj}
                className="latest-grid"
                columnClassName="latest-grid-col">
                {articles}
            </Masonry>
            
        </div>
    );
}

export default LatestIndex;
