// Assets
import moment from 'moment';
import FamilyImage from './../../assets/img/children/family.jpg';
import DisplayImageContainer from '../../containers/images/display-image-container';


function LatestArticleItem(props) {

    // Handle the intro
    var intro = props.article.Intro.replace(/(<([^>]+)>)/gi, "");
    var ending = intro.slice(intro.length - 1);
    intro = intro.replace("&rsquo;", "'");
    if (ending !== "." && ending !== "?" && ending !== "!") {
        intro = intro + '...';
    }


    // Handle the title
    var title = props.article.Title.replace("&#34;", '"').replace("&#34;", '"').replace("&quot;", '"').replace("&quot;", '"');

    return (
        <div className="latest-item news">
            <a className="full-lnk" href={"/latest/" + props.article.Slug}>{props.article.Title}</a>
            
            {props.article.ImageID > 0 ? (
                <DisplayImageContainer
                    imageId={props.article.ImageID}
                    size="half"
                    class="other-article-img"
                    alt={props.article.Title + ' other article'}
                />
            ) : (
                <img src={ FamilyImage } alt="Family" class="other-article-img"/> 
            )
            }

            {!props.article.Published && <span className='latest-article-draft edit-img-btn'>Draft</span>}

            <div className="copy">
                <h3>{ title }</h3>
                <p className="datetime">{moment(props.article.PublishDate).format('Do MMMM YYYY') }</p>
                <p>{ intro }</p>

                <a className="lnk" href={ "/latest/" + props.article.Slug }>Read more</a>
            </div>
        </div>
    );
}

export default LatestArticleItem;
