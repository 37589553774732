// TODO: Handling CSRF attacks?!?!
// Submit an MFA token to complete login

// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../../store/flash';

// View components
import Wording from '../../../components/admin/wording/wording';
import Redirects from '../../../components/admin/redirects/redirects';


const RedirectContainer = (props) => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [error, setError] = useState(false);
    const [flashState, flashActions] = useFlash();
    const [authCookie] = useCookies(['csirt']);

    const [redirects,setRedirects] = useState([]);
    const [loading, toggleIsLoading] = useState(true)
    const [isSaving,toggleIsSaving] = useState(false)
    const [newRdr, setNewRdr] = useState({oldSlug: "", newSlug: ""});
    const [showCreate, setShowCreate] = useState(false);

    const navigate = useNavigate();

    let loginUrl = "/admin";

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadRedirects = () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined") {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the article you are looking for. PLease sign in and try again.", style: "red" });
            navigate("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/redirects', {
            method: 'get',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            setRedirects(data.detail);
            toggleIsLoading(false)
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            }
            navigate(loginUrl);
        });
    }

    // Update word 
    const updateRdr = (rdrId, rdrSlug) => {
        let newRdrs = [...redirects];
        let rdrIndex = newRdrs.findIndex(rdr => rdr.id === rdrId);
        newRdrs[rdrIndex].newSlug = rdrSlug;
        setRedirects(newRdrs);
    }

    // Save the wording
    const saveRdr = (e, rdrId, isCreate) => {
        e.preventDefault();
        if (isCreate) {
            toggleIsSaving("new");
        } else {
            toggleIsSaving(rdrId);
        }

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined") {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the article you are looking for. PLease sign in and try again.", style: "red" });
            navigate("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Work out the data to save
        var rdr;
        if (!isCreate) {
            let rdrIndex = redirects.findIndex(rdr => rdr.id === rdrId);
            rdr = redirects[rdrIndex];
            console.log("EDITinG " + rdrId);
        } else {
            rdr = newRdr;
            console.log("CREATING " + rdrId);
            console.log(rdr);
        }

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/redirects', {
            method: 'post',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
            body: JSON.stringify(rdr)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            
            toggleIsSaving(false);
            if (data.rslt === "success") {
                setError({ msg: "The redirect has been saved successfully.", style: "grn" });
                setShowCreate(false);
                loadRedirects();
                return;
            }

            if (isCreate) {
                setError({ msg: "Sorry but there was an error creating the redirect. Please try again.", style: "red" });
            } else {
                setError({ msg: "Sorry but there was an error updating the redirect. Please try again.", style: "red" });
            }

        })
        .catch(function (error) {
            toggleIsSaving(false);
            setError({ msg: "Sorry but there was an error updating the redirect. Please try again.", style: "red" });
        });

    }

    // Delete a redirect
    let deleteRedirect = (e, rdrId) => {

        e.preventDefault();

        // Confirm that they do want to delete it 
        if (!window.confirm("Are you sure you want to delete this redirect?")) {
            return;
        }

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_COOKIE] === "undefined") {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the article you are looking for. PLease sign in and try again.", style: "red" });
            navigate("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/redirects/' + rdrId, {
            method: 'delete',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if (data.rslt === "success") {
                setError({ msg: "The redirect has been deleted successfully.", style: "grn" });
                loadRedirects();
                return;
            }
            setError({ msg: "There was an error deleting the redirect. Please try again.", style: "red" });
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            }
            navigate(loginUrl);
        });
    }

    const updateNewRdr = (key, value) => {
        var updatedNewRdr = {...newRdr}
        updatedNewRdr[key] = value;
        setNewRdr(updatedNewRdr);
    }     

    const toggleShowCreate = (e) => {
        e.preventDefault();
        setShowCreate(!showCreate);
    }

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        loadRedirects();        
    }, []);
    /* eslint-enable */

    return (
        <Redirects 
            loading={loading} 
            redirects={redirects} 
            updateRdr={updateRdr } 
            saveRdr={ saveRdr } 
            error={error} 
            isSaving={isSaving} 
            newRdr={newRdr}
            setNewRdr={updateNewRdr}
            showCreate={showCreate}
            toggleShowCreate={toggleShowCreate}
            deleteRedirect={deleteRedirect}
            {...props} 
        />
    );
}

export default RedirectContainer;