import EditImgContainer from '../../../containers/admin/blocks/edit-img-container';
import ExtractContent from '../../../utilities/extract-content';
import EditableButton from '../../admin/common/forms/editable-button';

function BannerAdmin(props) {

    let content = ExtractContent.block(props.block, 'draft');

    return (
        <section className="banner">

            <EditImgContainer
                fieldName="img"
                requiredSize="full"
                image={content["img"]}
                updateField={props.updateField}
                imageId={content["img"]}
            />
            
            <div className="content">
                <div className="width-10 centred">
                    <h4>&quot;<input type="text" className="white mtop-big" placeholder="Quote..." defaultValue={content["quote"]} onInput={(e) => props.updateField("quote", e.target.value)} onBlur={() => props.saveBlock()} />&quot;</h4>
                   
                    <EditableButton
                        btnTxt={typeof content["action-txt-1"] !== "undefined" ? content["action-txt-1"] : "Enter link text..."}
                        fieldName="action-txt-1"
                        fieldNameLnk="action-link-1"
                        btnLnk={typeof content["action-link-1"] !== "undefined" ? content["action-link-1"] : ""}
                        updateField={props.updateField}
                        saveBlock={props.saveBlock}
                    />

                </div>
            </div>
        </section>
    );
}

export default BannerAdmin;
