import moment from 'moment';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

// Components
import LatestArticleItem from './../../latest/latest-article-item';

// Assets
import Header from './../../common/header';
import EditImgContainer from '../../../containers/admin/blocks/edit-img-container';
import ArticleBarContainer from '../../../containers/admin/latest/cms/article-bar-container';
import Permissions from '../../../utilities/permissions';
function AdminArticle(props) {

    if (!props.current) {
        return <p>Loading...</p>
    }

    return (
        <article className="content article">
            <Header page="latest" />
            {props.authState && Permissions.actionCheck("menus", "crud", props.authState.permissions) &&
                <ArticleBarContainer actions={['cms','article-admin','discard','publish']} {...props} />
            }

            <div className="width-8 centred">
                <div className="header-img">
                    <EditImgContainer
                        fieldName="ImageID"
                        requiredSize="full"
                        image={ props.current.Image}
                        updateField={props.updateField}
                        imageId={ props.current.Image.id}
                    />
                </div>

                <h1 className="article-header">
                    <input type="text" className="left-align" placeholder="Title..." defaultValue={props.current.Title} onInput={(e) => props.updateField("Title", e.target.value)} onBlur={() => props.saveArticle()} />
                </h1>
                <h6>
                    {moment(props.current.PublishDate).format('Do MMMM YYYY')} <span>&loz;</span> Written by 
                    <input type="text" className="left-align" placeholder="Author..." defaultValue={props.current.Author} onInput={(e) => props.updateField("Author", e.target.value)} onBlur={() => props.saveArticle()} />
                </h6>

                <div className="article-content">
                    <CKEditor
                        editor={InlineEditor}
                        data={props.current.Content ? props.current.Content : "Enter content..."}
                        config={{
                            toolbar: ['bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                        }}
                        onBlur={(event, editor) => {
                            const data = editor.getData();
                            props.updateField('Content', data, true)
                        }}
                    />
                </div>
            </div>

            <div className="width-12 centred">
                <div className="other-articles">
                    <h4>Other articles</h4>

                    { props.latestArticles ? (
                        <div>
                            {props.latestArticles.map((article, i) =>
                                <LatestArticleItem key={ "other-" + i } minTxt={true} article={article.NewsArticle} />
                            )}
                        </div>
                    ) : (
                        <p>Loading...</p>
                    )}
                    
                </div>
            </div>
        </article>
    );
}

export default AdminArticle;
