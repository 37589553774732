import React, { Fragment, useState } from 'react';

import MenusContainer from '../../containers/pages/menus-container';
import Logo from './../../assets/img/core/esuubi-logo.webp'
import DisplayImageContainer from '../../containers/images/display-image-container';

function Header(props) {

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = (e) => {
        e.preventDefault();
        setMenuOpen(!menuOpen);
    }

    return (
        <Fragment>
            <header>
                <div className="logo left">
                    <a href="/">
                        <img src={ Logo } alt="Esuubi logo" />
                    </a>
                </div>
                <div className="response right">
                    <button className="menu-toggle" onClick={ (e) => toggleMenu(e) }>Menu</button>
                    <MenusContainer menuSlug="secondary" />
                </div>
                <nav className={ menuOpen ? "width-6 centred open" : "width-6 centred"}>
                    <MenusContainer menuSlug="main" />
                </nav>
            </header>

            { props.page !== "latest" && props.page.imageId !== 0 && 
                <div className="info-header-wrap">
                    <DisplayImageContainer
                        imageId={props.page.imageId}
                        size="head"
                        class="info-header-img"
                        isBgImage={true}
                    />

                    <div className="width-10 centred info-title">
                        { props.page.subtitle !== "" && 
                            <h2>{ props.page.subtitle }</h2>
                        }
                        {props.page.title !== "" &&
                            <h1>{props.page.title}</h1>
                        }
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default Header;
