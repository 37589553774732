// Core React 
import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";

// Components
import BlockBarContainer from '../../containers/admin/blocks/cms/block-bar-container';
import Permissions from '../../utilities/permissions';
import BlocksContainer from '../../containers/pages/blocks-container';

function Home(props) {

    if (props.loading) return "";

    return (
        <Fragment>
            <Helmet>
                <title>{props.page.title} :: Esuubi</title>
                <meta name="description" content={props.page.metaDescription} />
                <meta name="keywords" content={props.page.metaKeywords} />
            </Helmet>
            {props.authState && Permissions.actionCheck("menus", "crud", props.authState.permissions) &&
                <BlockBarContainer {...props} />
            }
            { props.page.blocks.map( (block, i) =>
                <Fragment key={"block-" + i}>
                    <BlocksContainer block={block} />
                </Fragment>
            )}
        </Fragment>
    );
}

export default Home;
