import { Link } from 'react-router-dom';
import ExtractContent from '../../../utilities/extract-content';
import DisplayImageContainer from '../../../containers/images/display-image-container';

function RightImage(props) {

    let content = ExtractContent.block(props.block);

    return (
        <div className="right-img">
            <DisplayImageContainer
                imageId={content["img"]}
                size="half"
                class="img-right-hang"
                isBgImage={true}
            />
            <div className="content">
                <section className="width-6 align-left written-content">
                    <div className="title-block">
                        {content.title !== "" &&
                            <h3>{content.title}</h3>
                        }
                        {content.subtitle !== "" &&
                            <h4>{content.subtitle}</h4>
                        }
                    </div>

                    <div dangerouslySetInnerHTML={{ __html: content.content }}></div>

                    {typeof content["btn-txt"] != "undefined" && typeof content["btn-lnk"] != "undefined" &&
                        <Link className="btn sml" to={content["btn-lnk"]}>{content["btn-txt"]}</Link>
                    }
                </section>
            </div>
        </div>
    );
}

export default RightImage;
